﻿<script lang="ts">
  import { onMount, onDestroy, afterUpdate, tick} from "svelte";
  import {fade } from "svelte/transition";
  /*basic page layout / loading */
  import Loading from "../components/Loading.svelte";
  import ProjectTabs from "../components/ProjectTabs.svelte";
  import Tabs from "../components/Tabs.svelte";

  /*components used to display page */
  import ReorderableList from "../components/questionSet/ReorderableList.svelte";


  /*get the project and update it*/
  import { getProject, updateProject} from "../services/project";
  import {
    getData, 
    questionSet, 
    editQuestionSet, 
    addQuestion, 
    addSection,
    returnDataset,
    changeMapProject, 

  } from "../stores/questionset";


  import { pageTitle } from "../stores/store";

  // the Project ID from the URL
  export let projectId;
  let project;
  //let questionSet;
     let reorder = false;
     $: reorderText = reorder ? 'Edit Nodes' : 'Reorder Questions';

     let originalOrderIds;

  function getNumberFromString(str) {
    const match = str.match(/\d+$/);
    return match ? parseInt(match[0], 10) : null;
  }

  onMount(async () => {
    project = await getProject(projectId);
    console.log(project);
    $pageTitle = project.name;
    getData(project.questionSet);
    console.log($editQuestionSet);

  });

  let hasError = false;
  let savedButton = 'Save';
  let nodeButton = 'Add Node';
  let propsButton = 'Add Properties to node';
  let sectionButton = 'Add Text section';
  let map = Object.keys($editQuestionSet.mapBasic).length > 0 ? true : false;
  $: mapButton = map ? 'Not a Map project': 'Create Map project';
  let mapRemove = false;
 // $: numOfQuestion = $editQuestionSet.questions .length;

  const save = async () => {
      console.log('save');
    hasError = false;
    try {
      const questionSet = returnDataset();
      // if still here, it's valid JSON
      project.questionSet = questionSet;
      const result = await updateProject(project);
      console.log("Result", result);
      getData(result.questionSet);
      setTimeout(() => {  savedButton = 'Set Saved'; }, 300);
      setTimeout(() => {  savedButton = 'Save'; }, 5000);
    } catch (error) {
      hasError = true;
      savedButton = 'Error Occured';
    }
  };

  //believe i can use this to do a save popup but not too sure how 
  onDestroy(() => {
      if($editQuestionSet != $questionSet){
          console.log('not saved');
      }
  })

// Get the offset position of the navbasr
let sticky = false;
let y;

afterUpdate(() => {
        if(y >= 108){
            sticky = true;
        } else {
            sticky = false;
        }
})

let reorderScroll;

//need to include sections
async function scrollToElement() {
    await tick();
   // reorderScroll();
   let i = $editQuestionSet.questions.length -1;
    let id = $editQuestionSet.questions[i].id;

    const el = document.querySelector('#'+id);
    console.log(el);
    if (!el) return;
    el.scrollIntoView({
          behavior: 'smooth'
    });
}
let disabled= false;

$: {
    disabled = reorder ? true : false;
}



</script>
<svelte:window bind:scrollY={y}/>

<ProjectTabs {projectId} />
{#if project}
  <div class="panel">
      <div class="question-edit-section">
          <div class="questionSet-container">
              <div class="org-edit">
                  <form>
                      <div class="short">
                          <label for="org-name">Organisation</label>
                          <input type="text" id="org-name" name="org-name" bind:value="{$editQuestionSet.labelOrganisation}">
                      </div>  
                      <div class="short">
                          <label for="project-name">Project</label>
                          <input type="text" id="project-name" name="project-name" bind:value="{$editQuestionSet.labelProject}">
                      </div>
                      <div class="short">
                          <label for="intro-text">Project intro Text</label>
                          <input type="text" id="intro-title" name="intro-title" bind:value="{$editQuestionSet.sections.intro.title}">
                      </div>
                      <div class="big">
                          <textarea id="intro-text" name="intro-text" placeholder="paste html here" bind:value="{$editQuestionSet.sections.intro.text}"/>
                      </div>
                      <div class="short">
                          <label for="outro-text">Project outro Text</label>
                          <input type="text" id="outro-title" name="outro-title" bind:value="{$editQuestionSet.sections.outro.title}">
                      </div>
                      <div class="big">
                          <textarea id="outro-text" name="outro-text" placeholder="paste html here" bind:value="{$editQuestionSet.sections.outro.text}"/>
                      </div>
                      <div class="short">
                          <label for="type-title">Type filter box title</label>
                          <input type="text" id="type-title" name="type-title" bind:value="{$editQuestionSet.config.filters.type.title}">
                      </div>
                      <div class="short">
                          <label for="filter-action">Type filter action</label>
                          <select
                          name="filter-action-dropdown"
                          id="filter-action-dropdown"
                                bind:value="{$editQuestionSet.config.filters.type.filterType}">

                            <option value="nodesAdd">Add nodes</option>
                            <option value="nodesRemoved">Remove nodes</option>
                            <option value="nodesUnlinked">Unlink nodes</option>
                            <option value="nodesRemovedMaintainingLinks">Maintain links and remove nodes</option>

                        </select>
                        </div>
                        <div class="short">
                          <label for="lenses-action">Type Lenses action</label>
                          <select
                          name="lenses-action-dropdown"
                          id="lenses-action-dropdown"
                                bind:value="{$editQuestionSet.config.filters.type.lenseType}">

                            <option value="nodesAdd">Add nodes</option>
                            <option value="nodesRemoved">Remove nodes</option>
                            <option value="nodesUnlinked">Unlink nodes</option>
                            <option value="nodesRemovedMaintainingLinks">Maintain links and remove nodes</option>

                        </select>
                        </div>
                        <div class="short">
                          <label for="flag">Project Flag</label>
                          <select
                          name="project-flag-dropdown"
                          id="project-flag-dropdown"
                                bind:value="{$editQuestionSet.config.projectFlag}">

                            <option value="sm">sm</option>
                            <option value="relm">relm</option>
                            <option value="understory">understory</option>
                        </select>  
                        </div>   
                        <div class="short">
                          <label for="public-project">Is this a public project?</label>
                          <div class="checkbox-container">
                            <input
                              type="checkbox"
                              id="public-project"
                              name="public-project"
                              bind:checked="{$editQuestionSet.config.publicProject}"
                              >
                          </div>
                        </div>
                        <div class="short">
                          <label for="updatable">Can users access the forms?</label>
                          <div class="checkbox-container">
                            <input
                              type="checkbox"
                              id="updatable"
                              name="updatable"
                              bind:checked="{$editQuestionSet.config.updatable}"
                              >
                          </div>
                        </div>
                        <div class="short">
                          <label for="published">Can users access the visualiser?</label>
                          <div class="checkbox-container">
                            <input
                              type="checkbox"
                              id="published"
                              name="published"
                              bind:checked="{$editQuestionSet.config.published}"
                              >
                          </div>
                        </div>
                        <div class="short">
                          <label for="multi-answers">Can users answer the forms multiple times?</label>
                          <div class="checkbox-container">
                            <input
                              type="checkbox"
                              id="multi-answers"
                              name="multi-answers"
                              bind:checked="{$editQuestionSet.config.multiAnswers}"
                              >
                          </div>
                        </div>
                        <div class="short">
                          <label for="multi-rings">Visualiser nodes to display with multiple rings</label>
                          <div class="checkbox-container">
                            <input
                              type="checkbox"
                              id="multi-rings"
                              name="multi-rings"
                              bind:checked="{$editQuestionSet.config.multiRings}"
                              >
                          </div>
                        </div>
                        <div class="short">
                          <label for="timeline">Visualiser has timeline controls</label>
                          <div class="checkbox-container">
                            <input
                              type="checkbox"
                              id="timeline"
                              name="timeline"
                              bind:checked="{$editQuestionSet.config.timeline}"
                              >
                          </div>
                        </div>
                        <div class="short">
                          <label for="tour">Visualiser has tour controls</label>
                          <div class="checkbox-container">
                            <input
                              type="checkbox"
                              id="tour"
                              name="tour"
                              bind:checked="{$editQuestionSet.config.tour}"
                              >
                          </div>
                        </div>
                      <div class="map-container">
                        {#if !mapRemove}
                        <button
                          class="btn btn-map"
                          on:click|preventDefault="{() => mapRemove = !mapRemove}"
                        >
                          {mapButton}
                        </button>
                        {:else}
                             {#if map}
                                <p>Are you sure you want to do this?</p>
                                <button
                                    class="btn btn-deletey"
                                    on:click|preventDefault="{() => {map = !map; mapRemove = false; changeMapProject(map);}}"
                                >
                                Yes, undo map project
                                </button>
                                <button
                                  class="btn btn-deleten"
                                  on:click|preventDefault="{() => mapRemove = false}"
                                >
                                  No, keep as a map project
                                </button>
                             {:else}
                                <p>Are you sure you want to create a map project?</p>
                                <button
                                    class="btn btn-deletey"
                                    on:click|preventDefault="{() => {map = !map; mapRemove = false; changeMapProject(map);}}"
                                >
                                Yes, create one
                                </button>
                                <button
                                  class="btn btn-deleten"
                                  on:click|preventDefault="{() => mapRemove = false}"
                                >
                                  No, keep as a regular project
                                </button>
                             {/if}
                        {/if}
                      {#if map}
                      <div class="short">
                          <label for="lat">Base Lat</label>
                          <input type="number" id="lat" name="lat" bind:value="{$editQuestionSet.mapBasic.lat}">
                      </div>
                      <div class="short">
                          <label for="lat">Base Long</label>
                          <input type="number" id="long" name="long" bind:value="{$editQuestionSet.mapBasic.long}">
                      </div>
                      <div class="short">
                          <label for="radius">Base radius</label>
                          <input type="number" id="radius" name="radius" bind:value="{$editQuestionSet.mapBasic.radiusKm}">
                      </div>
                      {/if}
                   </div>
                  </form>
              </div>
              <div class="node-container">
              
                  {#if $editQuestionSet.questions.length == 0}
                    <h1>This question set is empty, start by adding a node to add a question</h1>
                  {:else}
                 
                   <ReorderableList  reorder={reorder} bind:questionSet={$editQuestionSet} map={map}/>
          
                  {/if}

              </div>
          </div>
          <div class="add-node" >
              <div class="inner-add-node {sticky == true ? 'sticky' : ''}">
              <button class="btn btn-node" on:click|preventDefault="{() => {
                  addQuestion('node'); 
                  setTimeout(() => {  nodeButton = 'Node Question added'; }, 200);
                  setTimeout(() => {  nodeButton = 'Add Node'; }, 3000);
                  scrollToElement();}}"{disabled}>
                  {nodeButton}
              </button>
              <button class="btn btn-props" on:click|preventDefault="{() => {
                  addQuestion('properties');
                  setTimeout(() => {  propsButton = 'Property Question added'; }, 200);
                  setTimeout(() => {  propsButton = 'Add Properties to node'; }, 3000);
                  scrollToElement();}}"{disabled}>
                  {propsButton}
              </button>
              <button class="btn btn-section" on:click|preventDefault="{() => {
                addSection();
                setTimeout(() => {  sectionButton = 'Section added'; }, 200);
                setTimeout(() => {  sectionButton = 'Add Section'; }, 3000);
                scrollToElement();}}"{disabled}>
                {sectionButton}
              </button>
              <button class="btn btn-reorder" on:click="{() => {reorder = !reorder; if(!reorder){ originalOrderIds = $editQuestionSet.questions.map((q) => q.id);}}}">{reorderText}</button>
              <button class="btn btn-save" on:click|preventDefault="{() => save()}">{savedButton}</button>
                </div>
          </div>
      </div>
  </div>
{:else}
  <Loading />
{/if}
