<script lang="ts">
  import { onMount } from "svelte";
  import {fade } from "svelte/transition";
    import Text from "./Text.svelte";
  import Checkbox from "./Checkbox.svelte";
  import Map from "./Map.svelte"; 
  import Select from "./Select.svelte";
  import Quant from "./Quant.svelte";

  import {editQuestionSet, deleteQuestion, changeProps, changeNodeKind} from "../../stores/questionset";

  export let data;
  export let index;
  //not used but put it in as its in node
  export let self;
  export let map;


  let subQuestionLabel = data.label.substring(50, 0);

  $: data.label, updateSubLabel();

  function updateSubLabel() {
    subQuestionLabel = data.label.substring(50, 0);
    if (data.label.length > 50) {
      subQuestionLabel = subQuestionLabel + "...";
    }
  }

  let remove = false;


     function componentConstructorClass(componentName){   
      switch(componentName){
        case 'Text': return Text;
        case 'CheckBox': return Checkbox;
        case 'Map': return Map;
        case 'Select': return Select;
        case 'Quant': return Quant;
    }
   }
   //need to have an array of propertyLabels and names and display different then should be done
  // let propertyLable = '';
   let propertyType = data.data.dataset.properties.length <= 1 ? 'Answers' : 'EachAnswer';
   let propertyLables = [];
   data.data.dataset.properties.forEach((prop) => {
       propertyLables.push($editQuestionSet.config.translations[prop.name] == undefined ? prop.name : $editQuestionSet.config.translations[prop.name]);
   })
   
   //$editQuestionSet.config.translations[data.data.dataset.name] == undefined ? data.data.dataset.name : $editQuestionSet.config.translations[data.data.dataset.name];

   //when the node type changes or the translations set changes find the new label
   //no longer works cos need to pass index
  $: $editQuestionSet.config.translations && findAllLables();

   //dont know if this needs to be in store  
  function findLable(index){
	   if($editQuestionSet.config.translations[data.data.dataset.properties[index].name] == undefined ){
		   $editQuestionSet.config.translations[data.data.dataset.properties[index].name] = propertyLables[index];
	   }
	   propertyLables[index] = $editQuestionSet.config.translations[data.data.dataset.properties[index].name];

  }

  //find all labels 
  function findAllLables(){
      data.data.dataset.properties.forEach((prop, index) => {
          if($editQuestionSet.config.translations[prop.name] == undefined ){
		    $editQuestionSet.config.translations[prop.name] = propertyLables[index];
	      }
	   propertyLables[index] = $editQuestionSet.config.translations[prop.name];
      })
  }

   //this works as it creates a label at this name
   function changeLable(index){
	   if(data.data.dataset.properties[index].name.length > 0){
		   $editQuestionSet.config.translations[data.data.dataset.properties[index].name] = propertyLables[index];
		   console.log( $editQuestionSet.config.translations);
	   }else{
		   console.log('name is empty');
	   }
   }
   
   let selectNode = data.data.id == 'selfProps' ? "__self__" : data.data.dataset.index;
   //let value = (selectNode == "question--"+(index-1)) ? "__priorAnswers__" : selectNode;
  let value = selectNode;
  if(selectNode == ''){
      value = '__self__';
      selectNodeChange();
  }

   function selectNodeChange(){
	   if(value == "__self__"){
		   changeProps(data, index, "self", null);
	   }/*else if(value == "__priorAnswer__"){
		   changeProps(data, index, "props", index-1)
	   }*/else{
		   //let num = Number(value.replace(/[^0-9\.]+/g, ""));
		   changeProps(data, index, "props", value)
	   }
   }

   let minimise = false;
  //true or false on if it has filters
  let filters = [];
  let filterOrders = [];
  let filterTitles = [];
  let filterTypes = [];
  let lenseTypes = [];
  data.data.dataset.properties.forEach((prop) => {
      let filter = Object.keys($editQuestionSet.config.filters).includes(prop.name);
      filters.push(filter);
      let order = $editQuestionSet.config.filters[prop.name] != undefined ? $editQuestionSet.config.filters[prop.name].order : -1;
      filterOrders.push(order);
      let title = $editQuestionSet.config.filters[prop.name] != undefined ? $editQuestionSet.config.filters[prop.name].title : '';
      filterTitles.push(title);
      let type = $editQuestionSet.config.filters[prop.name] != undefined ? $editQuestionSet.config.filters[prop.name].filterType : 'nodesRemoved';
      filterTypes.push(type);
      let lenseType = $editQuestionSet.config.filters[prop.name] != undefined ? $editQuestionSet.config.filters[prop.name].lenseType : 'nodesAdd';
      lenseTypes.push(lenseType);
  })


  //broken in current state
  /*$: filters.forEach((f, i) =>{
      if(f){
       if($editQuestionSet.config.filters[data.data.dataset.properties[i].name] != undefined){
           if('order' in  $editQuestionSet.config.filters[data.data.dataset.properties[i].name]){
           $editQuestionSet.config.filters[data.data.dataset.properties[i].name].order = filterOrders[i];
           }
           if('title' in  $editQuestionSet.config.filters[data.data.dataset.properties[i].name]){
           $editQuestionSet.config.filters[data.data.dataset.properties[i].name].title = filterTitles[i];
           }
           if('filterType' in $editQuestionSet.config.filters[data.data.dataset.properties[i].name]){
               $editQuestionSet.config.filters[data.data.dataset.properties[i].name].filterType = filterTypes[i];
           }
            if('lenseType' in $editQuestionSet.config.filters[data.data.dataset.properties[i].name]){
               $editQuestionSet.config.filters[data.data.dataset.properties[i].name].lenseType = lenseTypes[i];
           }
       }

   }
   })*/


   function changeFilter(index){
       if(data.data.dataset.properties[index].name.length == 0){
           filters[index] = false;
           return;
       }

       if(filters[index]){
          $editQuestionSet.config.filters[data.data.dataset.properties[index].name] = {filterType: 'nodesRemoved', lenseType: 'nodesAdd', title: '', order: Object.keys($editQuestionSet.config.filters).length+1};
          filterOrders[index] = $editQuestionSet.config.filters[data.data.dataset.properties[index].name].order;
       }else{
           delete $editQuestionSet.config.filters[data.data.dataset.properties[index].name];
           $editQuestionSet.config = $editQuestionSet.config;
       }
   }
   
    function addLinkAction(data, index){
         //need a way to check if its answers and then find the answers from that 
         //at the moment it is only answers 

         if(data.data.dataset.properties[index].value == '__answers__'){
             let keys = [];

             if(data.kind == 'Checkbox'){
                keys = data.data.choices;
             }else if(data.kind == 'Select'){
                 keys = data.data.suggestions;
             }

             keys.forEach((key) => {
                  if(!(key in $editQuestionSet.config.linkActions)){
                        $editQuestionSet.config.linkActions[key] = {type: 'collaborationType', colour: 'highlight'};
                  }
             }) 
         } 
         //not sure how to process the __answer0__ values yet

  }
  
  function removeLinkAction(data, index){
      //really this doesnt need index it should check if they are indival or all
      if(data.data.dataset.properties[index].value == '__answers__'){
             let keys = [];

             if(data.kind == 'Checkbox'){
                keys = data.data.choices;
             }else if(data.kind == 'Select'){
                 keys = data.data.suggestions;
             }
          keys.forEach((key) => {
              if(key in $editQuestionSet.config.linkActions){
                  delete $editQuestionSet.config.linkActions[key];
              }
          });
          $editQuestionSet.config.linkActions = $editQuestionSet.config.linkActions;
      }
  }

  //change to all answers or indivual
  function changePropertyType(){
      if(propertyType == 'Answers'){
          //change to a single value

          //dont think i need to go and remove link and filter actions as it should then check for them at the end
          //let [firstProp, ...removedProps] = [[data.data.dataset.properties.pop()], ...data.data.dataset.properties];
          //console.log(firstProp, removedProps);
          propertyLables.splice(0, 1);
          filters.splice(0, 1);
          filterOrders.splice(0, 1);
          filterTitles.splice(0, 1);
          filterTypes.splice(0, 1);
          lenseTypes.splice(0, 1);
          data.data.dataset.properties.splice(0, 1);
          //need a better system here
          data.data.dataset.properties[0].value = '__answers__';

      }else if(propertyType == 'EachAnswer'){
          //dont think i need to do anything
      }

  }

  //add property
  //not working as somethings type isnt loading up yet
  function addProperty(){
      console.log(data.data.dataset.properties);
      //this is crude need a better check on what is missing etc
      let id = data.data.dataset.properties.length;
      let value = '__answer'+id+'__';

      propertyLables.push('');
      filters.push(false);
      filterOrders.push(-1);
      filterTitles.push('');
      filterTypes.push('nodesRemoved');
      lenseTypes.push('nodesAdd');
      data.data.dataset.properties = [...data.data.dataset.properties, {name: '', value: value}];
     // console.log(data.data.dataset.properties);

  }

  //deleteProperty
  function deleteProperty(index){
      data.data.dataset.properties.splice(index, 1);
      //and everything else
  }

</script>

<div class="properties" id="{data.id}">
 {#if data}
 <div class="intro">
	  <div class="sticker">
           Q{index+1}
      </div>
    {#if data.label.length > 0}
    <h2>{subQuestionLabel}</h2>
    {:else}
    <h2>Preview the first 50 char of the Question text</h2>
    {/if}
	<div class="min-container">
    <div class="min" on:click={() => {minimise = !minimise}} on:keydown >

      {#if !minimise}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4.37 1.47"
          ><path style="fill:#FFF12C" d="M0 0h4.37v1.47H0z" /></svg
        >
      {:else}
        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4.37 4.37"
          ><defs
            ><style>
              .cls-1 {
                fill: #FFF12C;
              }
            </style></defs
          ><path class="cls-1" d="M0 1.53h4.37V3H0z" /><path
            class="cls-1"
            d="M1.49 0h1.47v4.37H1.49z"
          /></svg
        >
      {/if}
    </div>
    </div>
  </div>

  {#if !minimise}
 <form>
	 <div class="q-bubble">
		 <div class="q-container">
	<label for="select-node">Select Node to add properties to</label>
	<select name="select-node" id="select-node" bind:value={value} on:change={selectNodeChange}>
		<option value="__self__">Self</option>
		<!--{#if index-1 >= 0}--
			<option value="__priorAnswers__">Prior Answers</option>
		{/if}-->
		{#each $editQuestionSet.questions as question, i}
			{#if i != index && i != $editQuestionSet.selfsetNum && question.create == 'node'}
				<option value="{question.id}">Question {i+1}</option>
			{/if}
		{/each}
	</select>
	</div>
	    <div class="q-container right">
        <label for="filter-action">Filter action</label>
            <select name="property-dropdown" id="property-dropdown" bind:value="{propertyType}" on:change={changePropertyType}>
                <option value="Answers">All Answers</option>
                <option value="EachAnswer">Individual Answers</option>
        </select>
        </div>

    {#each data.data.dataset.properties as prop, index}
            <div class="q-container right">
            <label for="name">Property Name (no space)</label>
            <input type="text" id="name" name="name" on:focusout={() => {findLable(index)}} bind:value="{data.data.dataset.properties[index].name}">
	        </div>
	        <div class="q-container right">
	        <label for="node-label">Readable property name</label>
	        <input type="text" id="node-label" name="node-label" on:focusout={() => {changeLable(index)}} bind:value="{propertyLables[index]}">
	        </div>
            <div class="q-container">
                <label for="filter">Create filter box?</label>
                <div class="checkbox-container">
                 <input
                    type="checkbox"
                    id="filter"
                    name="filter"
                    value="filter"
                    bind:checked="{filters[index]}"
                    on:change="{(e) => changeFilter(index)}"
                  />
            </div>
        </div>
        {#if filters[index] && prop.name.length >= 1}
            <div class="q-container num">
            <label for="filter-order">Order of filter & lense box</label>
            <input
              type="number"
              min="1"
              step="1"
              id="filter-order"
              name="filter-order"
              bind:value="{filterOrders[index]}"
            />
          </div>
          <div class="q-container">
	        <label for="filter-label">Filter & Lense box title</label>
	        <input type="text" id="filter-label" name="filter-label" bind:value="{filterTitles[index]}" >
	      </div>
          <div class="q-container">
              <label for="filter-action">Filter action</label>
                          <select
                          name="filter-action-dropdown"
                          id="filter-action-dropdown"
                                bind:value="{filterTypes[index]}"
                          >

                            <option value="nodesAdd">Add nodes</option>
                            <option value="nodesRemoved">Remove nodes</option>
                            <option value="nodesUnlinked">Unlink nodes</option>
                            <option value="nodesRemovedMaintainingLinks">Maintain links and remove nodes</option>

                        </select>
                        </div>
                        <div class="q-container">
                          <label for="filter-action">Lenses action</label>
                          <select
                          name="filter-action-dropdown"
                          id="filter-action-dropdown"
                                bind:value="{lenseTypes[index]}">

                            <option value="nodesAdd">Add nodes</option>
                            <option value="nodesRemoved">Remove nodes</option>
                            <option value="nodesUnlinked">Unlink nodes</option>
                            <option value="nodesRemovedMaintainingLinks">Maintain links and remove nodes</option>

                        </select>
                        </div>
        {/if}

    {#if data.kind != 'Text'}
    {#if $editQuestionSet.config.linkActions[prop.name] == undefined && prop.name.length >= 1}
                      <div>
                        <button class="btn btn-node" on:click|preventDefault="{() =>{addLinkAction(data, index)}}">
                          Add Link Action
                        </button>
                      </div>
                      {:else}
                        <!--this needs to loop through each answer -->
                                            <div class="q-container">
                                                <label for="link-type">What Type of link is this?</label>
                                                <select name="link-type" id="link-type" bind:value="{$editQuestionSet.config.linkActions[prop.name].type}">
                                                        <option value="linkingCapital">Linking</option>
                                                        <option value="bridgingCapital">Bridging</option>
                                                        <option value="collaborationType">Collaboration</option>
   
                                                </select>
                                            </div>

                                            <div class="q-container">
                                                <label for="link-colour">What Colour would you like?</label>
                                                <select name="link-colour" id="link-colour" bind:value="{$editQuestionSet.config.linkActions[prop.name].colour}">
                                                        <option value="highlight">Pink</option>
                                                        <option value="bridges">Blue</option>
                                                        <option value="highlightCommission">Purple</option>
                                                        <option value="highlightProduct">Red</option>
                                                        <option value="hightlightProcess">Light pink</option>
                                                </select>
                                            </div>
                       <div>
                        <button class="btn btn-node" on:click|preventDefault="{() =>{removeLinkAction(data, index)}}">
                          Remove Link Action
                        </button>
                      </div>
                      {/if}{/if}
                      <!--dont work yet-->
<!--{#if propertyType == 'EachAnswer'}
     <div>
        <button class="btn btn-node" on:click|preventDefault="{() =>{deleteProperty(index)}}">
            Delete Property
        </button>
    </div>
    {/if}
    {/each}
    {#if propertyType == 'EachAnswer'}
     <div>
        <button class="btn btn-node" on:click|preventDefault="{() =>{addProperty()}}">
            Add New Property
        </button>
    </div>
    {/if}-->
        {/each}
	<div class="q-container">
		<label for="question-type">Choose a Question type</label>
	<select name="question-type" id="question-type"  bind:value={data.kind} on:change={() => {changeNodeKind(data, index)}}>
		<option value="Text">text</option>
		<option value="CheckBox">checkbox</option>
		<option value="Select">select</option>
    <option value="DateTime">datetime</option>
        {#if map}
		<option value="Map">map</option>
        {/if}
        <option value="Quant">quant</option>
	</select>
	</div>
	</div>

	<div class="q-bubble">
      <div class="q-container-big">
        <label for="question-label">Question text</label>
        <textarea
          id="question-label"
          name="question-label"
          maxlength="200"
          placeholder="max 200 characters"
          bind:value="{data.label}"

        />
      </div>
    </div>
    

	<svelte:component this={componentConstructorClass(data.kind)} bind:data={data} bind:index={index}/>
</form>
<div class="delete-container">
    {#if !remove}
    <button
      class="btn btn-delete"
      on:click|preventDefault="{() => remove = true}"
    >
      Delete Question?
    </button>
    {:else}
    <p>Are you sure you want to delete this Property?</p>
    <button
      class="btn btn-deletey"
      on:click="{() => {deleteQuestion(data.id);}}"
    >
      Yes, Delete Question
    </button>
    <button
      class="btn btn-deleten"
      on:click|preventDefault="{() => remove = false}"
    >
      No
    </button>
    {/if}
  </div>
  {/if}
  {:else}
  <h1>Oops something went wrong node</h1>
  {/if}
</div>