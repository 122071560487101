<script lang="ts">
  import page from "page";
  import { currentUser, isAdmin, isSuperAdmin } from "../stores/store";

  export let projectId;
  const currentHref = page.current;

  const tabs = [];

    tabs.push({ label: "Project Details", href: `/projects/${projectId}` });


    
  tabs.push({
    label: "Participants",
    href: `/projects/${projectId}/participants`,
  });
  if ($isAdmin) {
    tabs.push({
      label: "Facilitators",
      href: `/projects/${projectId}/facilitators`,
    });
  }
 // if ($isAdmin) {
    tabs.push({ label: "Data Cleanup", href: `/projects/${projectId}/cleanup` });
 // }

  tabs.push({ label: "Invites", href: `/projects/${projectId}/invites` });
  if ($isSuperAdmin) {
    tabs.push({
      label: "Question set",
      href: `/projects/${projectId}/questions`,
    });
  }
    if ($isSuperAdmin) {
    tabs.push({
      label: "Question set editor",
      href: `/projects/${projectId}/questions-editor`,
    });
  }
  tabs.push({ label: "Stats", href: `/projects/${projectId}/stats` });
  if($isAdmin){
      tabs.push({label: "Compare", href: `/projects/${projectId}/compare`});
  }
</script>

<div class="tabs">
  {#each tabs as tab, index}
    <a href={tab.href} class="tab" class:is-active={currentHref == tab.href}>
      {tab.label}
    </a>
  {/each}
</div>

<style type="scss">
</style>
