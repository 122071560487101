<script lang="ts">
  import { onMount } from "svelte";
  import {fade } from "svelte/transition";
  import {editQuestionSet, addLinkEmptyObject, removeLinkEmptyObject} from "../../stores/questionset"

  export let data;
  export let index;

  //many to many 
  //currentLocation
  //fieldLabels

  function addLink(){
    data.data.dataset.links = [...data.data.dataset.links, {
                    linkType: [],  
                    source: '',
                    target: '',
                    otherKey: [], 
                }];
  }

  function removeLink(index){
    data.data.dataset.links.splice(index, 1);
  }

  
  

  let colour = false;
  let colourButton = "Add custom colours";

  //if there is no setself value u cant have the links set to setself otherwise it wont works
  /*$: if(data.data.dataset.source == '__self__' && $editQuestionSet.selfsetNum == -1){
	  data.data.dataset.source = '';
  }

  $: if(data.data.dataset.target == '__self__' && $editQuestionSet.selfsetNum == -1){
	  data.data.dataset.target = '';
  }*/
  

</script>
{#if data}
		{#if data.data.helpText}
			<div class="q-bubble">
		<div class="q-container-big">
		<label for="help-text">Help Text</label>
         <textarea id="help-text" name="help-text" bind:value="{data.data.helpText}" maxlength="200"/>
		 </div>
		 </div>
		 {/if}

     {#if 'currentLocation' in data.data}
     <div class="q-bubble">
      <div class="q-container">
          <label for="current-location">Would you like a current location button?</label>
          <div class="checkbox-container">
            <input
              type="checkbox"
              id="current-location"
              name="current-location"

              bind:checked="{data.data.currentLocation}"
            />
          </div>
        </div>
        </div>
     {/if}

<div class="q-bubble">
<div class="q-container">
    <label for="allow-empty">Require an answer?</label>
    <div class="checkbox-container">
      <input
        type="checkbox"
        id="allow-empty"
        name="allow-empty"
        value="empty"
        bind:checked="{data.allowEmpty}"
      />
    </div>
  </div>
  </div>

  {#if data.create != 'properties'} {#if data.data.id != 'setSelf' } {#if data.data.dataset}
  <div class="q-bubble">
  {#each data.data.dataset.links as link, linkIndex}

  <div class="q-container">
    <label for="source">What is the link source</label>
    <select name="source" id="source" bind:value="{link.source}">
      {#if $editQuestionSet.selfsetNum > -1}
      <option value="__self__">self</option>
      {/if}
      <option value="__answers__">answers</option>
      {#if index-1 >= 0 }
                        {#if $editQuestionSet.questions[index-1]?.create == 'node'}
                            <option value="__priorAnswers__">Prior Answers</option>
                            <option value="__priorAnswer__">Prior Answer</option>
                            {/if}
                        {/if}

      {#each $editQuestionSet.questions as question, i}
				{#if i != index && i != $editQuestionSet.selfsetNum}
        <option value={`__answer${i}__`}> answer {i}</option>
        <option value={`__answers${i}__`}> answers {i}</option>
        <option value={`__manyAnswer${i}__`}> many Answer {i}</option>
        <option value={`__manyAnswers${i}__`}> many Answer {i}</option>
				{/if}
			{/each}
    </select>
  </div>
  <div class="q-container">
    <label for="target">What is the link target</label>
    <select name="target" id="target" bind:value="{link.target}">
      {#if $editQuestionSet.selfsetNum > -1}
      <option value="__self__">self</option>
      {/if}
      <option value="__answers__">answers</option>
      {#if index-1 >= 0 }
                        {#if $editQuestionSet.questions[index-1]?.create == 'node'}
                            <option value="__priorAnswers__">Prior Answers</option>
                            <option value="__priorAnswer__">Prior Answer</option>
                            {/if}
                        {/if}
      {#each $editQuestionSet.questions as question, i}
				{#if i != index && i != $editQuestionSet.selfsetNum}
        <option value={`__answer${i}__`}> answer {i}</option>
        <option value={`__answers${i}__`}> answers {i}</option>
        <option value={`__manyAnswer${i}__`}> many Answer {i}</option>
        <option value={`__manyAnswers${i}__`}> many Answer {i}</option>
				{/if}
			{/each}
    </select>
  </div>
  <div class="q-container">
    <label for="type">What is the link type</label>
    <input
      type="text"
      id="type"
      name="type"
      bind:value="{link.linkType}"
    />
  </div>
  <div>
    <button class="btn btn-node" on:click|preventDefault="{() => {addLinkEmptyObject(index, linkIndex, 'otherKey')}}">
        Add custom value
    </button>
  </div>
  {#if link.otherKey.length >= 1} 
  {#each link.otherKey as pair, i}
  <div class="q-container-double">
    <div class="q-container">
      <label for="key-{i}">Key</label>
      <input type="text" id="key-{i}" name="key-{i}" bind:value="{pair.key}" />
    </div>
    <div class="q-container">
      <label for="value-{i}">Value</label>
      <input
        type="text"
        id="value-{i}"
        name="value-{i}"
        bind:value="{pair.value}"
      />
    </div>
  </div>
  <div>
    <button class="btn btn-node" on:click|preventDefault="{() => {removeLinkEmptyObject(index, linkIndex, i, 'otherKey')}}">
        Delete Link Pair
    </button>
  </div>
  {/each} {/if}


{#if linkIndex > 1}
<div>
  <button class="btn btn-node" on:click|preventDefault="{() => {removeLink(index)}}">
      Delete Link
  </button>
</div>
{/if}
{/each}
<div>
  <button class="btn btn-node" on:click|preventDefault="{() => {addLink()}}">
      Add Link
  </button>
</div>
</div>
{/if} {/if} {/if}
 <!--- {#if data.create != 'properties'}
<div class="q-bubble">
  <div>
    <button
      class="btn btn-node"
      on:click|preventDefault="{() => {
                           colour = !colour;
                           if(colour){
                               colourButton = 'Remove custom colours';
                           }else{
                               colourButton = 'Add custom colours';
                           }
                       }
                       }"
    >
      {colourButton}
    </button>
  </div>
  {#if colour}
  <div class="q-container-double">
    <div class="q-container">
      <label for="col-1">inner</label>
      <input
        type="text"
        id="col-1"
        name="col-1"
        bind:value="{data.colours.colour1}"
      />
    </div>
    <div class="q-container">
      <label for="col-2">outer</label>
      <input
        type="text"
        id="col-2"
        name="col-2"
        bind:value="{data.colours.colour2}"
      />
    </div>
  </div>
  {/if}
</div>
 {/if}-->
{/if}
