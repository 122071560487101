<script lang="ts">
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";
  import {
    editQuestionSet,
    addField,
    removeField,
    addEmptyObject,
    removeKeyValue,
    addLinkEmptyObject,
    removeLinkEmptyObject,
  } from "../../stores/questionset";

  export let data;
  export let index;


  let choices =
    "choices" in data.data
      ? typeof data.data.choices == "object"
        ? "other"
        : data.data.choices
      : "";
  let choicesArray =
    "choices" in data.data
      ? typeof data.data.choices == "object"
        ? data.data.choices
        : [""]
      : [""];

  $: if ("choices" in data.data) {
    data.data.choices = choices == "other" ? choicesArray : choices;
  }

  function onChangeChoices(el) {
      if(choices == 'other'){
          el.currentTarget.parentNode.parentNode.querySelector('.choice').firstChild.focus();      
      }
  }

  let colour = false;
  let colourButton = "Add custom colours";

  function addLink(){
    data.data.dataset.links = [...data.data.dataset.links, {
                    linkType: [],  
                    source: '',
                    target: '',
                    otherKey: [], 
                }];
  }

  function removeLink(index){
    data.data.dataset.links.splice(index, 1);
  }

  //if there is no setself value u cant have the links set to setself otherwise it wont work
  /*$: if(data.data.dataset.source == '__self__' && $editQuestionSet.selfsetNum == -1){
	  data.data.dataset.source = '';
  }

  $: if(data.data.dataset.target == '__self__' && $editQuestionSet.selfsetNum == -1){
	  data.data.dataset.target = '';
  }*/

 
</script>
{#if data}
<div class="q-bubble">
  {#if ('choices' in data.data)}
  <div class="q-container">
    <label for="choices-dropdown">Set choices</label>
    <select
      name="choices-dropdown"
      id="choices-dropdown"
      bind:value="{choices}"
    >
      {#if index-1 >= 0}
      <option value="__priorAnswers__">Prior Answers</option>
      {/if} {#each $editQuestionSet.questions as question, i} {#if i != index &&
      i != $editQuestionSet.selfsetNum && i != index-1}
      <option value="{question.id}">Question {i+1} Answers</option>
      {/if} {/each}
      <option value="other">Set Options</option>
    </select>
  </div>
  {#if choices == 'other'} {#each choicesArray as choices, i}
  <div class="q-container-long choice">
    <!--<label for="option-{i}">Option (label)</label>-->
    <input
      type="text"
      id="option-{i}"
      name="option-{i}"
      bind:value="{choices}"
    />
    {#if choicesArray.length > 1}
    <div>
      <button class="btn btn-node" on:click|preventDefault="{() => {removeField(index, 'choices', i); choicesArray = data.data.choices;}}">
        Delete field
      </button>
    </div>
    {/if}
  </div>
  {/each}
  <div>
    <button class="btn btn-node" on:click|preventDefault="{() =>{addField(index, 'choices'); choicesArray = data.data.choices;}}">
      Add field
    </button>
  </div>

  {/if} {/if}
  {#if 'minSelected' in data.data}
  <div class="q-container num">
    <label for="min-selected">Set the minimum answers</label>
    <input
      type="number"
      min="0"
      step="1"
      id="min-selected"
      name="min-selected"
      bind:value="{data.data.minSelected}"
    />
  </div>
  {/if} {#if 'maxSelected' in data.data}
  <div class="q-container num">
    <label for="max-selected">Set the maximum answers</label>
    <input
      type="number"
      min="1"
      step="1"
      id="max-selected"
      name="max-selected"
      bind:value="{data.data.maxSelected}"
    />
  </div>
  {/if} {#if 'other' in data.data}
  <div class="q-container num">
    <label for="other">How many other fields</label>
    <input
      type="number"
      min="0"
      step="1"
      id="other"
      name="other"
      bind:value="{data.data.other}"
    />
  </div>
  {/if}
  {#if 'manyToMany' in data.data}
        <div class="q-container">
          <label for="many-to-many">Is this a many to many question?</label>
          <div class="checkbox-container">
            <input
                type="checkbox"
                id="many-to-many"
                name="many-to-many"
                bind:checked="{data.data.manyToMany}"
            />
        </div>
        </div>
    {/if}
    {#if 'allowEmpty' in data}
    <div class="q-container">
    <label for="allow-empty">Require an answer?</label>
    <div class="checkbox-container">
      <input
        type="checkbox"
        id="allow-empty"
        name="allow-empty"
        value="empty"
        bind:checked="{data.allowEmpty}"
      />
    </div>
  </div>
  {/if}
</div>
{#if data.create != 'properties'} {#if data.data.id != 'setSelf' } {#if
data.data.dataset}
<div class="q-bubble">
{#each data.data.dataset.links as link, linkIndex}

  <div class="q-container">
    <label for="source">What is the link source</label>
    <select name="source" id="source" bind:value="{link.source}">
      {#if $editQuestionSet.selfsetNum > -1}
      <option value="__self__">self</option>
      {/if}
      <option value="__answers__">answers</option>
      {#if index-1 >= 0 }
                        {#if $editQuestionSet.questions[index-1]?.create == 'node'}
                            <option value="__priorAnswers__">Prior Answers</option>
                            <option value="__priorAnswer__">Prior Answer</option>
                            {/if}
                        {/if}

      {#each $editQuestionSet.questions as question, i}
				{#if i != index && i != $editQuestionSet.selfsetNum}
					                <option value={`__answer${i}__`}> answer {i}</option>
                          <option value={`__answers${i}__`}> answers {i}</option>
                          <option value={`__manyAnswer${i}__`}> many Answer {i}</option>
                          <option value={`__manyAnswers${i}__`}> many Answer {i}</option>
				{/if}

			{/each}
    </select>
  </div>
  <div class="q-container">
    <label for="target">What is the link target</label>
    <select name="target" id="target" bind:value="{link.target}">
      {#if $editQuestionSet.selfsetNum > -1}
      <option value="__self__">self</option>
      {/if}
      <option value="__answers__">answers</option>
      {#if index-1 >= 0 }
                        {#if $editQuestionSet.questions[index-1]?.create == 'node'}
                            <option value="__priorAnswers__">Prior Answers</option>
                            <option value="__priorAnswer__">Prior Answer</option>
                            {/if}
                        {/if}
      {#each $editQuestionSet.questions as question, i}
				{#if i != index && i != $editQuestionSet.selfsetNum }
        <option value={`__answer${i}__`}> answer {i}</option>
        <option value={`__answers${i}__`}> answers {i}</option>
        <option value={`__manyAnswer${i}__`}> many Answer {i}</option>
        <option value={`__manyAnswers${i}__`}> many Answer {i}</option>
				{/if}
			{/each}
    </select>
  </div>
  <div class="q-container">
    <label for="type">What is the link type</label>
    <input
      type="text"
      id="type"
      name="type"
      bind:value="{link.linkType}"
    />
  </div>
  <div>
    <button class="btn btn-node" on:click|preventDefault="{() =>{addLinkEmptyObject(index, linkIndex, 'otherKey')}}">
      Add custom value
    </button>
  </div>
  {#if link.otherKey.length >= 1} {#each link.otherKey
  as pair, i}
  <div class="q-container-double">
    <div class="q-container">
      <label for="key-{i}">Key</label>
      <input type="text" id="key-{i}" name="key-{i}" bind:value="{pair.key}" />
    </div>
    <div class="q-container">
      <label for="value-{i}">Value</label>
      <input
        type="text"
        id="value-{i}"
        name="value-{i}"
        bind:value="{pair.value}"
      />
    </div>
  </div>
  <div>
    <button class="btn btn-node" on:click|preventDefault="{() =>{removeLinkEmptyObject(index, linkIndex, i, 'otherKey')}}">
      Remove Link Pair
    </button>
  </div>
  {/each} {/if}

{#if linkIndex > 1}
<div>
  <button class="btn btn-node" on:click|preventDefault="{() => {removeLink(index)}}">
      Delete Link
  </button>
</div>
{/if}
{/each}
<div>
  <button class="btn btn-node" on:click|preventDefault="{() => {addLink()}}">
      Add Link
  </button>
</div>
</div>
{/if} {/if} {/if}
 <!--- {#if data.create != 'properties'}
<div class="q-bubble">
  <div>
    <button
      class="btn btn-node"
      on:click|preventDefault="{() => {
                           colour = !colour;
                           if(colour){
                               colourButton = 'Remove custom colours';
                           }else{
                               colourButton = 'Add custom colours';
                           }
                       }
                       }"
    >
      {colourButton}
    </button>
  </div>
  {#if colour}
  <div class="q-container-double">
    <div class="q-container">
      <label for="col-1">inner</label>
      <input
        type="text"
        id="col-1"
        name="col-1"
        bind:value="{data.colours.colour1}"
      />
    </div>
    <div class="q-container">
      <label for="col-2">outer</label>
      <input
        type="text"
        id="col-2"
        name="col-2"
        bind:value="{data.colours.colour2}"
      />
    </div>
  </div>
  {/if} 
</div>
{/if}-->
{/if}
