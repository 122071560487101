<script lang="ts">
  import { onMount, tick } from "svelte";

  import { fade } from "svelte/transition";
  import Text from "./Text.svelte";
  import Checkbox from "./Checkbox.svelte";
  import Map from "./Map.svelte";
  import Select from "./Select.svelte";
  import {
    changeSelf,
    checkSelf,
    editQuestionSet,
    changeNodeKind,
    removeKeyValue,
    addEmptyObject,
    deleteQuestion
  } from "../../stores/questionset";

  export let data;
  export let self;
  export let map;
  export let index;


  let subQuestionLabel = data.label.substring(50, 0);

  $: data.label, updateSubLabel();

  function updateSubLabel() {
    subQuestionLabel = data.label.substring(50, 0);
    if (data.label.length > 50) {
      subQuestionLabel = subQuestionLabel + "...";
    }
  }

  let remove = false;

  function componentConstructorClass(componentName) {
    switch (componentName) {
      case "Text":
        return Text;
      case "CheckBox":
        return Checkbox;
      case "Map":
        return Map;
      case "Select":
        return Select;
    }
  }

  /*all to do with changing nodetype*/
  let setTypes = ["org", "person", "location", "direction", "parentorg"];

  //if the nodeType = to anothe value then set the nodeType to be other
  let nodeType = data.data.dataset.nodeType.map((nodeType) =>
    setTypes.includes(nodeType) ? nodeType : "other"
  );
  let nodeName = data.data.dataset.nodeType;

  let nodeLabels = data.data.dataset.nodeType.map((nodeName) => ($editQuestionSet.config.translations[nodeName] || nodeName));


  function addNodeType() {
    nodeType = [...nodeType, "other"];
    nodeLabels.push({ nodeName: "other", nodeLabel: "Custom Node Type" });
  }

  function removeNodeType(index: number) {
    if (nodeType.length > 1) {
      nodeType.splice(index, 1);
      nodeLabels.splice(index, 1); // Remove the corresponding label
      nodeType = [...nodeType];
      nodeLabels = [...nodeLabels];
    }
  }


  let setself = {
    self: data.data.dataset.self == undefined ? false : true,
    message: "",
  };

  let minimise = false;



  function addFilter(key) {
      if(!(key in $editQuestionSet.config.filters)){
          if(key in $editQuestionSet.config.filterActions){
             $editQuestionSet.config.filters[key] = {filterType: $editQuestionSet.config.filterActions[key], lenseType: 'nodesAdd', title: '', order: Object.keys($editQuestionSet.config.filters).length+1}
          }else{
            $editQuestionSet.config.filters[key] = {filterType: 'nodesRemoved', lenseType: 'nodesAdd', title: '', order: Object.keys($editQuestionSet.config.filters).length+1}
          }       
      
      }
  }

  function removeFilter(key) {
      if(key in $editQuestionSet.config.filters){
       delete $editQuestionSet.config.filters[key];
       $editQuestionSet.config.filters = $editQuestionSet.config.filters;
      }

  }

  let nested = data.nested != null;
  $: if (!nested) {
    data.nested = null;
  }

  // Update nodeType and nodeLabel when necessary
 /* $: {
    nodeLabels.forEach(({ nodeName }, index) => {
      const currentNodeType = nodeType[index];
      if (currentNodeType === "other") {
        nodeLabels[index].nodeLabel = nodeName; // Set the node label for the "other" type
      }
    });
  }

  // When nodeType or translations change, update the label for each nodeName
  $: {
    (nodeType || $editQuestionSet.config.translations) && nodeLabels.forEach(({ nodeName }, index) => {
     // findLabel(nodeName); // Find or set the label for each nodeName
    });
  }

  // This will update the translation label for each nodeName in nodeLabels
  $: {
    nodeLabels.forEach(({ nodeName, nodeLabel }) => {
      if (nodeLabel) {
        $editQuestionSet.config.translations[nodeName] = nodeLabel;
      }
    });
  }*/



</script>
<div class="node {self ? 'selfNode' : ''}" id="{data.id}">
  {#if data}
  <div class="intro">
      <div class="sticker">
          Q{index+1}
      </div>
    {#if data.label.length > 0}
    <h2>{subQuestionLabel}</h2>
    {:else}
    <h2>Preview the first 50 char of the Question text</h2>
    {/if}
    <div class="min-container">
    <div class="min" on:click={() => {minimise = !minimise}} on:keydown >

      {#if !minimise}
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4.37 1.47"
          ><path style="fill:#FFF12C" d="M0 0h4.37v1.47H0z" /></svg
        >
      {:else}
        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4.37 4.37"
          ><defs
            ><style>
              .cls-1 {
                fill: #FFF12C;
              }
            </style></defs
          ><path class="cls-1" d="M0 1.53h4.37V3H0z" /><path
            class="cls-1"
            d="M1.49 0h1.47v4.37H1.49z"
          /></svg
        >
      {/if}
    </div>
    </div>

  </div>
  {#if !minimise}
  <form>
    <div class="q-bubble">
         {#if data.kind != 'Map'}
         {#key nodeType}
         {#each nodeType as type, i}
      <div class="q-container">
        <label for="node-type">Select or create a node type</label>
        <select name="node-type" id="node-type" bind:value="{type}">
          <option value="org">Organisation</option>
          <option value="parentorg">Parent organisation</option>
          <option value="direction">Direction</option>
          <option value="person">Person</option>
          <option value="location">Location</option>
          <option value="other">Other</option>
        </select>
      </div>

     {#if type == 'other'}
      <div class="q-container right">
        <label for="node-name">Custom node type</label>
        <input
          type="text"
          id="node-name"
          name="node-name"

          bind:value="{nodeName[i]}">
      </div>
      {/if}

      <div class="q-container right">
        <label for="node-label">Readable node name</label>
        <input
          type="text"
          id="node-label"
          name="node-label"

          bind:value="{nodeLabels[i]}">
      </div>

      {#if i >0}
      <div>
        <button class="btn btn-node" on:click|preventDefault="{() =>{removeNodeType(i)}}">
         Remove Node type
        </button>
      </div>
      {/if}
      {/each}
      {/key}
      <div>
        <button class="btn btn-node" on:click|preventDefault="{() =>{addNodeType()}}">
          Add Node type
        </button>
      </div>

       <div>
    <button class="btn btn-node" on:click|preventDefault="{() =>{addEmptyObject(index, 'otherNodeType')}}">
      Add custom value
    </button>
  </div>
  {/if}
 {#if 'otherNodeType' in data.data.dataset}
  {#if data.data.dataset.otherNodeType.length >= 1} {#each data.data.dataset.otherNodeType
  as pair, i}
  <div class="q-container-double">
    <div class="q-container">
      <label for="key-{i}">Key</label>
      <input type="text" id="key-{i}" name="key-{i}" bind:value="{pair.key}" />
    </div>
    <div class="q-container">
      <label for="value-{i}">Value</label>
      <input
        type="text"
        id="value-{i}"
        name="value-{i}"
        bind:value="{pair.value}"
      />
    </div>
  </div>


  <div>
    <button class="btn btn-node" on:click|preventDefault="{() =>{removeKeyValue(index, i, 'otherNodeType'); removeFilter(pair.key)}}">
      Remove Node Pair
    </button>
  </div>
  <!--{#key pair.key}
    {#if $editQuestionSet.config.filters[pair.key] != undefined }
          <div class="q-container num">
            <label for="filter-order">Order of filter & lense box</label>
            <input
              type="number"
              min="1"
              step="1"
              id="filter-order"
              name="filter-order"
              bind:value="{$editQuestionSet.config.filters[pair.key].order}"
            />
          </div>
          <div class="q-container">
	        <label for="filter-label">Filter's & Lense's box title</label>
	        <input type="text" id="filter-label" name="filter-label" bind:value="{$editQuestionSet.config.filters[pair.key].title}" >
	      </div>


          
                           <div class="q-container">
                          <label for="filter-action">Filter action</label>
                          <select
                          name="filter-action-dropdown"
                          id="filter-action-dropdown"
                                bind:value="{$editQuestionSet.config.filters[pair.key].filterType}"
                          >

                            <option value="nodesAdd">Add nodes</option>
                            <option value="nodesRemoved">Remove nodes</option>
                            <option value="nodesUnlinked">Unlink nodes</option>
                            <option value="nodesRemovedMaintainingLinks">Maintain links and remove nodes</option>

                        </select>
                        </div>
                         <div class="q-container">
                          <label for="filter-action">Lenses action</label>
                          <select
                          name="filter-action-dropdown"
                          id="filter-action-dropdown"
                                bind:value="{$editQuestionSet.config.filters[pair.key].lenseType}">

                            <option value="nodesAdd">Add nodes</option>
                            <option value="nodesRemoved">Remove nodes</option>
                            <option value="nodesUnlinked">Unlink nodes</option>
                            <option value="nodesRemovedMaintainingLinks">Maintain links and remove nodes</option>

                        </select>
                        </div>

          <div>
          <button class="btn btn-node" on:click|preventDefault="{() =>{removeFilter(pair.key)}}">
            remove filter & lense
          </button>
          </div>

   
    {:else}

        <div>
        <button class="btn btn-node" on:click|preventDefault="{() =>{addFilter(pair.key)}}">
          add filter & lense
        </button>
        </div>
{/if}




    {/key}-->

  {/each} {/if}{/if}

      
      <div class="q-container">
        <label for="nested">Nest question?</label>
        <div class="checkbox-container">
          <input
            type="checkbox"
            id="nested"
            name="nested"
            value="nested"
            bind:checked="{nested}"
            >
        </div>
      </div>
      <!--need to fix so when you change nested back to false then it sets the value to null-->
      {#if nested}
        <div class="q-container">
          <label for="nest-value">Nest name</label>
          <input
            type="text"
            id="nest-value"
            name="nest-value"
            bind:value="{data.nested}">
          </div>
      {/if}
      <div class="q-container">
        <label for="question-type">Choose a Question type</label>
        <select
          name="question-type"
          id="question-type"
          bind:value="{data.kind}"
          on:change="{() => {changeNodeKind(data, index)}}">
          <option value="Text">text</option>
          <option value="Select">select</option>
          <option value="CheckBox">checkbox</option>
          <option value="DateTime">dateTime</option>
          <option value="Quant">quant</option>
          {#if map}
          <option value="Map">map</option>
          {/if}
        </select>
      </div>
      {#if data.kind == 'Text' || data.kind == 'Map'} {#if setself.message.length > 0}

      <div class="q-container">
        <p>{setself.message}</p>
        <!--could be a better way to do this ??? -->
        <button
          class="btn space"
          on:click|preventDefault="{() => {changeSelf(index); setself.message = ''; setself.self = (data.data.dataset.self == undefined) ? false : true;}}"
        >
          Change Self
        </button>
        <button
          class="btn"
          on:click|preventDefault="{() => {setself.message = ''}}"
        >
          Don't want to change self node
        </button>
      </div>
      {/if}

      <div class="q-container">
        <label for="set-self">Will this be the Self node?</label>
        <div class="checkbox-container">
          <input
            type="checkbox"
            id="set-self"
            name="set-self"
            value="set-self"
            bind:checked="{setself.self}"
            on:change="{() => setself = checkSelf(index, setself.self)}">
        </div>
      </div>
      {/if}
      <div class="q-container">
        <label for="project-encrypt">Encrypt personal data about others</label>
        <div class="checkbox-container">
          <input
            type="checkbox"
            id="project-encrypt"
            name="project-encrypt"
            value="project"
            bind:checked="{data.encrypt.project}"
          />
        </div>
      </div>
      <div class="q-container">
        <label for="consent-encrypt">Encrypt users own data</label>
        <div class="checkbox-container">
          <input
            type="checkbox"
            id="consent-encrypt"
            name="consent-encrypt"
            value="consent"
            bind:checked="{data.encrypt.consent}"
          />
        </div>
      </div>
    </div>

    <div class="q-bubble">
      <div class="q-container-big">
        <label for="question-label">Question text</label>
        <textarea
          id="question-label"
          name="question-label"
          bind:value="{data.label}"
          maxlength="200"
          placeholder="max 200 characters"
        />
      </div>
    </div>

    <svelte:component
      this="{componentConstructorClass(data.kind)}"
      bind:data="{data}"
      bind:index="{index}"
    />
  </form>

  <div class="delete-container">
    {#if !remove}
    <button
      class="btn btn-delete"
      on:click|preventDefault="{() => remove = true}"
    >
      Delete Question?
    </button>
    {:else}
    <p>Are you sure you want to delete this Node?</p>
    <button
      class="btn btn-deletey"
      on:click="{async () => 
      {deleteQuestion(data.id); 

      }}"
    >
      Yes, Delete Question
    </button>
    <button
      class="btn btn-deleten"
      on:click|preventDefault="{() => remove = false}"
    >
      No
    </button>
    {/if}
  </div>
    {/if}
  {:else}
  <h1>Oops something went wrong node</h1>
  {/if}
</div>
