<script lang="ts">
    import {deleteSection} from "../../stores/questionset";

export let data;
export let index;

let subQuestionLabel = data.title.substring(50, 0);


function updateSubLabel() {
    subQuestionLabel = data.title.substring(50, 0);
    if (data.title.length > 50) {
      subQuestionLabel = subQuestionLabel + "...";
    }
  }

  $: data.title, updateSubLabel();

  let remove = false;
  let minimise = false;

</script>
<div class="org-edit section">
    {#if data}
    <div class="intro">
         <div class="sticker">
              S{index+1}
         </div>
       {#if data.title.length > 0}
       <h2>{subQuestionLabel}</h2>
       {:else}
       <h2>Preview the first 50 char of the Section text</h2>
       {/if}
       <div class="min-container">
       <div class="min" on:click={() => {minimise = !minimise}} on:keydown >
   
         {#if !minimise}
           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4.37 1.47"
             ><path style="fill:#FFF12C" d="M0 0h4.37v1.47H0z" /></svg
           >
         {:else}
           <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4.37 4.37"
             ><defs
               ><style>
                 .cls-1 {
                   fill: #FFF12C;
                 }
               </style></defs
             ><path class="cls-1" d="M0 1.53h4.37V3H0z" /><path
               class="cls-1"
               d="M1.49 0h1.47v4.37H1.49z"
             /></svg
           >
         {/if}
       </div>
       </div>
     </div>
   
     {#if !minimise}


    <form>
        <div class="short">
            <label for="title">Section title</label>
            <input type="text" id="title" name="title" bind:value="{data.title}" placeholder="Please enter a title">
        </div>
        <div class="big">
            <label for="text">Section text</label>
            <textarea id="text" name="text" placeholder="paste html here" bind:value="{data.text}" />
        </div>
    </form>

    <!--need to add translation support-->
    <div class="delete-container">
        {#if !remove}
        <button
          class="btn btn-delete"
          on:click|preventDefault="{() => remove = true}"
        >
          Delete Section?
        </button>
        {:else}
        <p>Are you sure you want to delete this Section?</p>
        <button
          class="btn btn-deletey"
          on:click="{() => {deleteSection(data.id);}}"
        >
          Yes, Delete Section
        </button>
        <button
          class="btn btn-deleten"
          on:click|preventDefault="{() => remove = false}"
        >
          No
        </button>
        {/if}
    </div>
    {/if}
      {:else}
      <h1>Oops something went wrong node</h1>
      {/if}

</div>
