<script lang="ts">
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";
  import {
    editQuestionSet,
    addField,
    removeField,
    addEmptyObject,
    removeKeyValue,
  } from "../../stores/questionset";

  export let data;
  export let index;

  //many to many and scope
  //question and field labels 
  //percentage 

  let colour = false;
  let colourButton = "Add custom colours";

  let numOfAnswers =
    "fieldLabels" in data.data && data.data.fieldLabels.length != undefined
      ? data.data.fieldLabels.length
      : 1;
 // $: emptyLables = emptyLabels(numOfAnswers);

  let fieldLabels =
    "fieldLabels" in data.data
      ? typeof data.data.fieldLabels == "string"
        ? data.data.fieldLabels
        : "other" : "other";

  let labelsArray =
    "fieldLabels" in data.data
      ? typeof data.data.fieldLabels == "object"
        ? data.data.fieldLabels
        : [""]
      : [""];

  $: data.data.fieldLabels =
    fieldLabels == "other"
      ? labelsArray : fieldLabels;

  function emptyLabels(num) {
    let empty = [];
    for (let i = 0; i < num; i++) {
      empty.push("");
    }
    return empty;
  }

  function onChangeFieldLabel(el) {
      if(fieldLabels == 'other'){
          el.currentTarget.parentNode.parentNode.querySelector('.fieldLabels').firstChild.focus();      
      }
  }


    function addLinkAction(key){
      if(!(key in $editQuestionSet.config.linkActions)){
          $editQuestionSet.config.linkActions[key] = {type: 'collaborationType', colour: 'highlight'};
      }


  }

  function removeLinkAction(key){
      if(key in $editQuestionSet.config.linkActions){
          delete $editQuestionSet.config.linkActions[key];
          $editQuestionSet.config.linkActions = $editQuestionSet.config.linkActions;
      }
  }


  /*function getLabels(fieldLabels, num){
	  let newFieldLabels = [];
	  if(fieldLabels.length != num){
		  newFieldLabels = fieldLabels;
		  while(newFieldLabels.length != num){
			  if(newFieldLabels.length < num){
				  newFieldLabels.push('');
			  }
			  if(newFieldLabels.length > num){
				  newFieldLabels.pop();
			  }
		  }
	  }else{
		  newFieldLabels = fieldLabels;
	  }
		return newFieldLabels;
  }*/

  //if there is no setself value u cant have the links set to setself otherwise it wont work
  /* $: if(data.data.dataset.source == '__self__' && $editQuestionSet.selfsetNum == -1){
	  data.data.dataset.source = '';
  }

  $: if(data.data.dataset.target == '__self__' && $editQuestionSet.selfsetNum == -1){
	  data.data.dataset.target = '';
  }*/
</script>

{#if data}
<div class="q-bubble">
    {#if 'fieldLabels' in data.data}
        <div class="q-container">
            <label for="labels-dropdown">Add a label to the answer field?</label>
            <select
              name="labels-dropdown"
              id="labels-dropdown"
                    bind:value="{fieldLabels}"
              on:change="{onChangeFieldLabel}"
            >
            {#if index-1 >= 0}
                <option value="__priorAnswers__">Prior Answers</option>
            {/if} 
            {#each $editQuestionSet.questions as question, i} 
                {#if i != index && i != $editQuestionSet.selfsetNum && i != index-1} 
                    {#if $editQuestionSet.questions[i].kind == 'Text'}
                        <option value="{question.id}">Question {i+1} Answers</option>
            {/if} {/if} {/each}
            <option value="other">Set custom labels</option>
            </select>
        </div>
        {#if fieldLabels == 'other'} 
            {#each labelsArray as labels, i}
                <div class="q-container-long fieldLabels">
                <!--<label for="option-{i}">Answer label {i+1}</label>--->
                <input
                  type="text"
                  id="option-{i}"
                  name="option-{i}"
                  bind:value="{labels}"

                />
                {#if labelsArray.length > 1}
                    <button class="btn btn-node" on:click|preventDefault="{() => {removeField(index, 'fieldLabels', i); labelsArray = data.data.fieldLabels;}}">
                      Delete field
                    </button>
                {/if}
                </div>
            {/each}
            <div>
                <button class="btn btn-node" on:click|preventDefault="{() => {addField(index, 'fieldLabels'); labelsArray = data.data.fieldLabels;}}">
                    Add field
                </button>
            </div>
        {/if} 
       
    {/if}
    {#if 'minValue' in data.data}
      <div class="q-container num">
        <label for="min-selected">Set the minimum Number</label>
        <input
          type="number"
          min="0"
          step="1"
          id="min-selected"
          name="min-selected"
          bind:value="{data.data.minValue}"
        />
      </div>
      {/if} {#if 'maxValue' in data.data}
      <div class="q-container num">
        <label for="max-selected">Set the maximum Number</label>
        <input
          type="number"
          min="1"
          step="1"
          id="max-selected"
          name="max-selected"
          bind:value="{data.data.maxValue}"
        />
      </div>
      {/if}
    {#if 'allowEmpty' in data}
    <div class="q-container">
        <label for="allow-empty">Require an answer?</label>
        <div class="checkbox-container">
        <input
            type="checkbox"
            id="allow-empty"
            name="allow-empty"
            value="empty"
            bind:checked="{data.allowEmpty}"
        />
        </div>
    </div>
    {/if}

</div>
{/if}

