<script lang="ts">
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";
  import {
    addField,
    removeField,
    editQuestionSet,

    addLinkEmptyObject,
    removeLinkEmptyObject
  } from "../../stores/questionset";

  export let data;
  export let index;


  //other



  let colour = false;
  let colourButton = "Add custom colours";

  let numOfAnswers =
    "fieldLabels" in data.data && data.data.fieldLabels.length != undefined
      ? data.data.fieldLabels.length
      : 1;
  $: emptyLables = emptyLabels(numOfAnswers);

  let fieldLabels =
    "fieldLabels" in data.data
      ? typeof data.data.fieldLabels == "string"
        ? data.data.fieldLabels
        : data.data.fieldLabels.every((el) => el == "")
        ? "empty"
        : "other"
      : "other";
  let labelsArray =
    "fieldLabels" in data.data
      ? typeof data.data.fieldLabels == "object"
        ? data.data.fieldLabels
        : [""]
      : [""];

  $: data.data.fieldLabels =
    fieldLabels == "other"
      ? labelsArray
      : fieldLabels != "empty"
      ? fieldLabels
      : emptyLables;

  function emptyLabels(num) {
    let empty = [];
    for (let i = 0; i < num; i++) {
      empty.push("");
    }
    return empty;
  }

    function onChangeFieldLabel(el) {
      if(fieldLabels == 'other'){
          el.currentTarget.parentNode.parentNode.querySelector('.fieldLabels').firstChild.focus();      
      }
  }


  function addLink(){
    data.data.dataset.links = [...data.data.dataset.links, {
                    linkType: [],  
                    source: '',
                    target: '',
                    otherKey: [], 
                }];
  }

  function removeLink(index){
    data.data.dataset.links.splice(index, 1);
  }

  //if there is no setself value u cant have the links set to setself otherwise it wont work
  /*$: if(data.data.dataset.source == '__self__' && $editQuestionSet.selfsetNum == -1){
	  data.data.dataset.source = '';
  }

  $: if(data.data.dataset.target == '__self__' && $editQuestionSet.selfsetNum == -1){
	  data.data.dataset.target = '';
  }*/
  
</script>
{#if data}
<div class="q-bubble">
  {#if 'suggestions' in data.data}
  <div class="q-container">
    <label for="choices">set choices</label>
  </div>

  {#each data.data.suggestions as suggestion, i}
  <div class="q-container-long">
    <!--<label for="option-{i}">Option (label)</label>-->
    <input
      type="text"
      id="option-{i}"
      name="option-{i}"
      bind:value="{suggestion}"
    />
    {#if data.data.suggestions.length > 1}
    <div>
      <button
        class="btn btn-node"
        on:click|preventDefault="{() =>
		  removeField(index, 'suggestions', i)}"
      >
        Delete field
      </button>
    </div>
    {/if}
  </div>
  {/each}
  <div>
    <button
      class="btn btn-node"
      on:click|preventDefault="{() =>
		addField(index, 'suggestions')}"
    >
      Add field
    </button>
  </div>
  {/if} {#if 'fieldLabels' in data.data}
  <div class="q-container">
    <label for="labels-dropdown">Add a label to the answer field?</label>
    <select
      name="labels-dropdown"
      id="labels-dropdown"
      bind:value="{fieldLabels}"
      on:change="{onChangeFieldLabel}"
    >
      {#if index-1 >= 0}
      <option value="__priorAnswers__">Prior Answers</option>
      {/if} {#each $editQuestionSet.questions as question, i} {#if i != index &&
      i != $editQuestionSet.selfsetNum && i != index-1} {#if
      $editQuestionSet.questions[i].kind == 'Text'}
      <option value="{question.id}">Question {i+1} Answers</option>
      {/if} {/if} {/each}
      <option value="other">Yes</option>
      <option value="empty">No</option>
    </select>
  </div>
  {#if fieldLabels == 'other'} {#each labelsArray as labels, i}
  <div class="q-container-long fieldLabels">
    <!--<label for="option-{i}">Answer label {i+1}</label>-->
    <input
      type="text"
      id="option-{i}"
      name="option-{i}"
      bind:value="{labels}"
    />
    {#if labelsArray.length > 1}
    <button class="btn btn-node" on:click|preventDefault="{() => {removeField(index, 'fieldLabels', i); labelsArray = data.data.fieldLabels;}}">
    Delete field
    </button>
    {/if}
  </div>
  {/each}
  <div>
  <button class="btn btn-node" on:click|preventDefault="{() =>
    {addField(index, 'fieldLabels'); labelsArray = data.data.fieldLabels;}}">Add
    field
  </button>
  </div>
  {/if}
  {#if fieldLabels == 'empty'}
  <div class="q-container num">
    <label for="num-empty">How many answers can they give</label>
    <input
      type="number"
      min="1"
      step="1"
      id="num-empty"
      name="num-empty"
      bind:value="{numOfAnswers}"
    />
  </div>
  {/if} 

  {/if} 
  <div class="q-container">
    <label for="allow-empty">Require an answer?</label>
    <div class="checkbox-container">
      <input
        type="checkbox"
        id="allow-empty"
        name="allow-empty"
        value="empty"
        bind:checked="{data.allowEmpty}"
      />
    </div>
  </div>

  <div class="q-container">
    <label for="other">Would you like other values?</label>
    <div class="checkbox-container">
      <input
          type="checkbox"
          id="other"
          name="other"
          bind:checked="{data.data.other}"
      />
  </div>
  </div>

{#if 'manyToMany' in data.data}
        <div class="q-container">
          <label for="many-to-many">Is this a many to many question?</label>
          <div class="checkbox-container">
            <input
                type="checkbox"
                id="many-to-many"
                name="many-to-many"
                bind:checked="{data.data.manyToMany}"
            />
        </div>
        </div>
        {#if data.data.manyToMany === true}
        <div class="q-container num">
          <label for="starting-num">How many answers for each many to many?</label>
          <input
            type="number"
            min="1"
            step="1"
            id="starting-num"
            name="starting-num"
            bind:value="{data.data.startingFields}"
          />
      </div>
        {/if}
    {/if}
    </div>
{#if data.create != 'properties'} {#if data.data.id != 'setSelf' } {#if data.data.dataset}
<div class="q-bubble">
  {#each data.data.dataset.links as link, linkIndex}
  <div class="q-container">
    <label for="source">What is the link source</label>
    <select name="source" id="source" bind:value="{link.source}">
      {#if $editQuestionSet.selfsetNum > -1}
      <option value="__self__">self</option>
      {/if}
      <option value="__answers__">answers</option>
      {#if index-1 >= 0 }
                        {#if $editQuestionSet.questions[index-1].create == 'node'}
                            <option value="__priorAnswers__">Prior Answers</option>
                            <option value="__priorAnswer__">Prior Answer</option>
                            {/if}
                        {/if}

      {#each $editQuestionSet.questions as question, i}
				{#if i != index && i != $editQuestionSet.selfsetNum}
        <option value={`__answer${i}__`}> answer {i}</option>
        <option value={`__answers${i}__`}> answers {i}</option>
        <option value={`__manyAnswer${i}__`}> many Answer {i}</option>
        <option value={`__manyAnswers${i}__`}> many Answer {i}</option>
				{/if}
			{/each}
    </select>
  </div>
  <div class="q-container">
    <label for="target">What is the link target</label>
    <select name="target" id="target" bind:value="{link.target}">
      {#if $editQuestionSet.selfsetNum > -1}
      <option value="__self__">self</option>
      {/if}
      <option value="__answers__">answers</option>
      {#if index-1 >= 0 }
                        {#if $editQuestionSet.questions[index-1].create == 'node'}
                            <option value="__priorAnswers__">Prior Answers</option>
                            <option value="__priorAnswer__">Prior Answer</option>
                            {/if}
                        {/if}
      {#each $editQuestionSet.questions as question, i}
				{#if i != index && i != $editQuestionSet.selfsetNum }
        <option value={`__answer${i}__`}> answer {i}</option>
        <option value={`__answers${i}__`}> answers {i}</option>
        <option value={`__manyAnswer${i}__`}> many Answer {i}</option>
        <option value={`__manyAnswers${i}__`}> many Answer {i}</option>
				{/if}
			{/each}
    </select>
  </div>
  <div class="q-container">
    <label for="type">What is the link type</label>
    <input
      type="text"
      id="type"
      name="type"
      bind:value="{link.linkType}"
    />
  </div>
  <div>
    <button class="btn btn-node" on:click|preventDefault="{() =>
      {addLinkEmptyObject(index, linkIndex, 'otherKey')}}">Add custom value
    </button>
  </div>
  {#if link.otherKey.length >= 1} 
  {#each link.otherKey as pair, i}
  <div class="q-container-double">
    <div class="q-container">
      <label for="key-{i}">Key</label>
      <input type="text" id="key-{i}" name="key-{i}" bind:value="{pair.key}" />
    </div>
    <div class="q-container">
      <label for="value-{i}">Value</label>
      <input
        type="text"
        id="value-{i}"
        name="value-{i}"
        bind:value="{pair.value}"
      />
    </div>
  </div>
  <div>
    <button class="btn btn-node" on:click|preventDefault="{() =>
      {removeLinkEmptyObject(index, linkIndex, i, 'otherKey')}}">Remove Link Pair
    </button>
  </div>
  {/each} {/if}
  {#if linkIndex > 1}
  <div>
    <button class="btn btn-node" on:click|preventDefault="{() => {removeLink(index)}}">
        Delete Link
    </button>
</div>
{/if}
  {/each}
  <div>
    <button class="btn btn-node" on:click|preventDefault="{() => {addLink()}}">
        Add Link
    </button>
</div>
</div>

{/if} {/if} {/if}
<!---  {#if data.create != 'properties'}
<div class="q-bubble">
  <div>
    <button
      class="btn btn-node"
      on:click|preventDefault="{() => {
                           colour = !colour;
                           if(colour){
                               colourButton = 'Remove custom colours';
                           }else{
                               colourButton = 'Add custom colours';
                           }
                       }
                       }"
    >
      {colourButton}
    </button>
  </div>
  {#if colour}
  <div class="q-container-double">
    <div class="q-container">
      <label for="col-1">inner</label>
      <input
        type="text"
        id="col-1"
        name="col-1"
        bind:value="{data.colours.colour1}"
      />
    </div>
    <div class="q-container">
      <label for="col-2">outer</label>
      <input
        type="text"
        id="col-2"
        name="col-2"
        bind:value="{data.colours.colour2}"
      />
    </div>
  </div>
  {/if}
</div>
 {/if}-->
{/if}

