import { derived, writable, get, type Writable } from "svelte/store";
import type { Question, TextNode, Select, Map, Checkbox, MapBasic} from "../types"

/*
*
* proplems i have is that this code is very reliant on positions in the dataset 
* so if it changes it would break 
*
*/

export type QuestionSet = {
    labelOrganisation: string,
    labelProject: string,
    questions: Question[],
    sections: {},
    config: {
        filterActions: {},
        linkActions: {},
        translations: Object,
        extraMarkup: string,
        filters: {},
        colourChanges: {},
        projectFlag: string,
        publicProject: Boolean,
        updatable: Boolean,
        published: Boolean,
        multiAnswers: Boolean,
        multiRings: Boolean,
        timeline: Boolean,
        tour: Boolean,
        delRel: []
    }
    selfsetNum: number,
    survey: string,
    mapBasic: MapBasic | {},

};

const findSetSelf = (questions) => {
    if (questions.length == 0) {
        return -1;
    } else {
        let self = questions.findIndex((q) => q.dataset[0][0] == 'setSelf');
        return self;
    }
}

//gets all the data from the questionset and saves it in more readable formate
const findData = (question, ids) => {
    let data = {};
    //console.log(question, ids);

    //is if this one is setself 
    const isSelf = question.dataset[0][0] == 'setSelf' ? true : false;

    if (question.create == 'node') {
        if (!isSelf) {
            //for link extra keys
            // Handle links (now an array of link objects)
            const setKeys = ['type', 'source', 'target'];
            let links = [];
            
            // Iterate over each link in the dataset (now an array of links)
            question.dataset[1][1].forEach((link) => {
                let newKeys = [];
                let datasetKeys = Object.keys(link);

                // Remove the setKeys (type, source, target) from the dataset keys
                for (let i = 0; i < setKeys.length; i++) {
                    datasetKeys = datasetKeys.filter(el => el != setKeys[i]);
                }

                // For each link, create a list of newKeys (additional properties for the link)
                datasetKeys.forEach((key) => {
                    let kv = {};
                    kv['key'] = key;
                    kv['value'] = link[key]; // Access the specific link object
                    newKeys.push(kv);
                });

                // Store the individual link's data, including newKeys
                links.push({
                    linkType: typeof(link.type) == 'string' ? [link.type] : link.type,  // Can be a string or array of strings
                    source: link.source,
                    target: link.target,
                    otherKey: newKeys, // Store newKeys for this link
                });
            });

            // Handle node types (now can be a string or array of strings)
            let datasetNodeKeys = Object.keys(question.dataset[0][1]);
            let newNodeTypes = [];
            for (let i = 0; i < datasetNodeKeys.length; i++) {
                datasetNodeKeys = datasetNodeKeys.filter(el => el != 'type');
            }

            // Store the other node properties (excluding the 'type')
            for (let i = 0; i < datasetNodeKeys.length; i++) {
                let kv = {};
                kv['key'] = datasetNodeKeys[i];
                kv['value'] = question.dataset[0][1][datasetNodeKeys[i]];
                newNodeTypes.push(kv);
            }

            // Construct the final data object, including multiple links
            data = {
                dataset: {
                    nodeType: typeof(question.dataset[0][1].type) == 'string' ? [question.dataset[0][1].type] : question.dataset[0][1].type,  // Can be string or array of strings
                    otherNodeType: newNodeTypes,  // Store secondary types
                    links: links,  // Multiple links, each with its own newKeys
                },
                id: 'node'
            };
            //console.log(data);
        } else {
            let datasetNodeKeys = Object.keys(question.dataset[1][1]);

            let newNodeTypes = [];
            for (let i = 0; i < datasetNodeKeys.length; i++) {
                datasetNodeKeys = datasetNodeKeys.filter(el => el != 'type' && el != 'survey');
            }
           //check for other node key values to add 
            for (let i = 0; i < datasetNodeKeys.length; i++) {
                let kv = {};
                kv['key'] = datasetNodeKeys[i];
                kv['value'] = question.dataset[1][1][datasetNodeKeys[i]];
                newNodeTypes.push(kv);
            }

            data = {
                dataset: {
                    nodeType: typeof(question.dataset[1][1].type) == 'string' ? [question.dataset[1][1].type] : question.dataset[1][1].type,
                    survey: question.dataset[1][1].survey,
                    otherNodeType: newNodeTypes,
                    self: true
                },
                id: 'setSelf'
            }
            //console.log('setSelf', data);
        }

    } else if (question.create == 'properties') {
        //property set against self node
        if (question.dataset[0][0] == 'selfProps') {
            let properties = [];
            for (const [key, value] of Object.entries(question.dataset[0][1])) {
                properties.push({ name: key, value: value })
            }
           data = {
               dataset: {
                   //name: Object.keys(question.dataset[0][1]).toString(),
                   //value: Object.values(question.dataset[0][1]).toString(),
                   properties: properties
               },
               id: 'selfProps'
            }
        //other node properties
        } else if (question.dataset[0][0] == 'nodeProps') {
            let properties = [];

            for (const [key, value] of Object.entries(question.dataset[0][2])) {
                properties.push({ name: key, value: value })
            }

            data = {
                dataset: {
                    //name: Object.keys(question.dataset[0][2]).toString(),
                    //value: Object.values(question.dataset[0][2]).toString(),
                    properties: properties,
                    //returning id as index as when we move them around it wont be connected otherwise 
                    index: ids[question.dataset[0][1]],

                    
                },
                id: 'nodeProps'
            }
        }    

    }
    if (question.kind == 'Text') {
        data['fieldLabels'] = question.fieldLabels || [''];
        data['suggestions'] = question.suggestions || [''];
        data['manyToMany'] = question.manyToMany || false;
        data['startingFields'] = question.startingFields || 10;
        data['checkLocationName'] = question.checkLocationName || false;
        data['extendable'] = question.extendable || false;
        data['maxAnswers'] = question.maxAnswers || 20;


    }


    if (question.kind == 'CheckBox') {
        data['fieldLabels'] = question.fieldLabels || [''];
        data['choices'] = question.choices || [''];
        data['minSelected'] = question.minSelected || 0;
        data['maxSelected'] = question.maxSelected || 99;
        data['manyToMany'] = question.manyToMany || false;
        data['other'] = 0;
    }

    if (question.kind == 'Select') {
        data['fieldLabels'] = question.fieldLabels || [''];
        data['suggestions'] = question.suggestions || [''];
        data['other'] = question.other || false;
        data['manyToMany'] = question.manyToMany || false;
        data['startingFields'] = question.startingFields || 10;
    }

    if (question.kind == 'Map') {
        data['helpText'] = question.helpText || '';
        data['currentLocation'] = question.currentLocation || false;
        data['manyToMany'] = question.manyToMany || false;
        data['fieldLabels'] = question.fieldLabels || [''];
    }

    if (question.kind == 'Quant') {
        data['fieldLabels'] = question.fieldLabels || [''];
        data['questionLabels'] = question.questionLabels || [''];
        data['minValue'] = question.minValue || 0;
        data['maxValue'] = question.maxValue || 10;
        data['percentage'] = question.percentage || false;
    }

    if(question.kind == 'DateTime'){
        data['fieldLabels'] = question.fieldlabels || [''];
    }

    return data;
    
}

//very similar to function above maybe could combine 
const dataStructure = (kind, type) => {
    //always creates an empty version
    //just need to see if it has enough data

    let data = {};
    if (type == 'node') {
        data = {
            dataset: {
                nodeType: [],  
                otherNodeType: [],  
                links: [{
                    linkType: [],
                    source: '',
                    target: '',
                    otherKey: []
                }],
                       
            },
            id: 'node'
        }
    } else if (type == 'properties') {
        //need a way to pass if user selected self then change to the other 
        data = {
            dataset: {
                value: '__answers__',
                properties: [{'key': 'value'}]
                
            },
            id: 'nodeProps'

        }
    }

    if (kind == 'Text') {
        data['fieldLabels'] = [''];
        data['suggestions'] = [''];
        data['manyToMany'] = false;
        data['startingFields'] = 10;
        data['checkLocationName'] = false;
        data['extendable'] = false;
        data['maxAnswers'] = 20;


    }


    if (kind == 'CheckBox') {
        data['fieldLabels'] = [''];
        data['choices'] = [''];
        data['minSelected'] = 0;
        data['maxSelected'] = 99;
        data['manyToMany'] = false;
        data['other'] = 0;
    }

    if (kind == 'Select') {
        data['fieldLabels'] = [''];
        data['suggestions'] = [''];
        data['other'] = false;
        data['manyToMany'] = false;
        data['startingFields'] = 10;
    }

    if (kind == 'Map') {
        data['helpText'] = '';
        data['currentLocation'] = false;
        data['manyToMany'] = false;
        data['fieldLabels'] = [''];
    }

    if (kind == 'Quant') {
        data['fieldLabels'] = [''];
        data['questionLabels'] = [''];
        data['minValue'] = 0;
        data['maxValue'] = 10;
        data['percentage'] = false;
    }

    if(kind == 'DateTime'){
        data['fieldLabels'] = [''];
    }


    return data;

}

const getId = (i, ids) => {
    let id = 'question--' + i;
    console.log('id', id, ids.includes(id), ids);
    if (ids.includes(id) === false) {
        return id;
    }
    let found = false;
    i += 1;
    while (found == false) {
        id = 'question--' + i;
        if (ids.includes(id)) {
            i += 1;
        } else {
            found = true;
        }
    }

    return id;
}

const getQuestions = (data) => {
    if (data.length == 0) {
        return [];
    } else {
        let questions = [];
        let ids = data.map((e) => { return e.id });
        //going to make the presumsion that all ids coming over will be different

        data.forEach((d, index) => {
            let id = '';
            if (d.id == undefined) {
                id = getId(index, ids);
                ids[index] = id;
            }

            let q = {
                id: d.id || id,
                kind: d.kind || 'Text',
                label: d.label || '',
                create: d.create || 'node',
                allowEmpty: !(d.allowEmpty) || false,
                nested: d.nested || null,
                lang: d.lang || null,
                encrypt: {
                    project: ('encrypt' in d) ? d.encrypt.includes('project') : false,
                    consent: ('encrypt' in d) ? d.encrypt.includes('consent') : false
                },
                data: findData(d, ids),
            }
            questions.push(q);
        })
        return questions;
    }
}

const getSections = (data) => {
    let sections = {};
    console.log('sections', data)
    if (data?.length == 0 || data == undefined) {
        sections = {
            "intro": {
                "title": "",
                "text": ""
            },
            "outro": {
                "title": "",
                "text": ""
            }
        };
    } else {
        if(!data.intro){
            data['intro'] = {
                "title": "",
                "text": ""
                };  
        }

        if(!data.outro){
            data['outro'] = {
                "title": "",
                "text": ""
            }
        }
        
        sections = {...data};
    }
    return sections;
}

//needs work
const jsonData = (question, ids) => {
    let data = [];
    if (question.create == 'node') {
        if (question.data.id != 'setSelf') {
            let node = {
                type: question.data.dataset.nodeType
            };

            if (question.data.dataset.otherNodeType.length > 0) {
                for (let i = 0; i < question.data.dataset.otherNodeType.length; i++) {
                    let key = question.data.dataset.otherNodeType[i].key;
                    node[key] = question.data.dataset.otherNodeType[i].value;
                }
            }

            data.push(['nodes', node])
            let links = [];
            question.data.dataset.links.forEach((link) => {

                let l = {
                    source: link.source,
                    target: link.target,
                    type: link.linkType
                }

                if (link.otherKey.length > 0) {
                    for (let i = 0; i < link.otherKey.length; i++) {
                        let key = link.otherKey[i].key;
                        l[key] = link.otherKey[i].value;
                    }
                }

            // console.log(links);


                links.push(l)
            });

            data.push(['links', links]);
        } else {
            let node = {
                type: question.data.dataset.nodeType,
                survey: question.data.dataset.survey
            };

            if (question.data.dataset.otherNodeType.length > 0) {
                for (let i = 0; i < question.data.dataset.otherNodeType.length; i++) {
                    let key = question.data.dataset.otherNodeType[i].key;
                    node[key] = question.data.dataset.otherNodeType[i].value;
                }
            }


            data.push(['setSelf']);
            data.push(['selfProps', node]);
        }
        
    } else if (question.create == 'properties') {
        if (question.data.id == 'nodeProps') {
            let properties = {};
            question.data.dataset.properties.forEach((prop) => {
                properties[prop.name] = prop.value
            });
            data.push(['nodeProps', ids.indexOf(question.data.dataset.index) , properties]);
            //data.push(['nodeProps', ids.indexOf(question.data.dataset.index), { [question.data.dataset.name]: question.data.dataset.value }]);

        } else if (question.data.id == 'selfProps') {
            let properties = {};
            question.data.dataset.properties.forEach((prop) => {
                properties[prop.name] = prop.value
            });
            data.push(['nodeProps', properties]);
            //data.push(['selfProps', {[question.data.dataset.name]: question.data.dataset.value }]);

        }
    }

    return data;

}

const jsonQuestions = (data) => {
    if (data.length == 0) {
        return [];
    } else {
        let questions = [];
        let ids = data.map((e) => { return e.id });
        data.forEach((d, index) => {
            let encrypt = [];
            if (d.encrypt.project) {
                encrypt.push('project');
            }
            if (d.encrypt.consent) {
                encrypt.push('consent');
            }
            if (!d.encrypt.consent && !d.encrypt.project) {
                encrypt.push('none');
            }

            //need a filter on this to remove unneeded values from data
            let questionData = {...d.data}
            delete questionData.id;
            delete questionData.dataset;

            if (Object.hasOwn(questionData, 'extendable') && questionData.extendable === false) {
                delete questionData.extendable;
                delete questionData.maxAnswers;
            }
        
            // Check if 'manyToMany' exists, then remove 'manyToMany' and 'startingFields' if it's false
            if (Object.hasOwn(questionData, 'manyToMany') && questionData.manyToMany === false) {
                delete questionData.manyToMany;
                if(Object.hasOwn(questionData, 'startingFields')){
                    delete questionData.startingFields;
                }
            }

            if(Object.hasOwn(questionData, 'checkLocationName') && questionData.checkLocationName === false){
                delete questionData.checkLocationName;
            }

            if(Object.hasOwn(questionData, 'currentLocation') && questionData.currentLocation === false){
                delete questionData.currentLocation;
            }

            // Extract necessary fields from questionData to avoid duplicates
            let q = {
                id: d.id,
                kind: d.kind,
                label: d.label,
                create: d.create,
                encrypt: encrypt,
                allowEmpty: !d.allowEmpty,
                dataset: jsonData(d, ids),
                // Only add these properties if they are valid
                ...(d.lang !== null ? { lang: d.lang } : {}),
                ...(d.nested !== null && d.nested !== undefined ? { nested: d.nested } : {}),
            };

            // Now spread only the remaining properties to avoid duplication
            questions.push({ ...q, ...questionData });

        })
        return questions;
    }

}

export const getData = (data) => {

    const setSelf = findSetSelf(data.questions);
    let survey = '';
    if (setSelf >= 0) {
        //dont like this so reliant on position
        survey = data.questions[setSelf].dataset[1][1].survey;
    }

    const questions = getQuestions(data.questions);
    const sections = getSections(data.sections);
    //console.log(data.config.translations, 'trans');


    //this will be kept the same so can check against

    //data.introText.replaceAll('</p>', '').replaceAll('<p>', '')
    let filterBoxes = 'filterBoxes' in data.config ? data.config.filterBoxes : [];
    let filterTitles = 'filterBoxTitles' in data.config ? data.config.filterBoxTitles : [];
    let lenseActions = 'lenseActions' in data.config ? data.config.lenseActions : [];
    let filterActions = 'filterActions' in data.config ? data.config.filterActions : [];
    let linkActions = 'linkActions' in data.config ? data.config.linkActions : [];
    let colourChanges = 'colourChanges' in data.config ? data.config.colourChanges : {};

    //something here doesnt like new propeties system
    let filterActionObject = filterActions.reduce((result, [key, value]) => {
        result[key] = value;
        return result;
    }, {});
    let linkActionObject = {};

    linkActions.forEach((action) => {
        linkActionObject[action[1]] = { type: action[0], colour: action[2] };
    })

    /*linkActions.reduce((result, [key, value]) => {
        result[key] = value;
        return result;
    }, {});*/


    let f = [];
    if (filterBoxes.length >= 1) {
        f = filterBoxes.map((x) => {
            return x[0]
        });
    }

    //this gets all the filter boxes and titles and combines them;
        if (f.indexOf('type') >= 1) {
            //move to beginning
            let typeIndex = f.indexOf('type');
            let typeFilter = [filterBoxes[typeIndex]];
            filterBoxes.splice(typeIndex, 1);
            filterBoxes = typeFilter.concat(filterBoxes);

            let typeName = filterTitles[typeIndex];
            filterTitles.splice(typeIndex, 1);
            filterTitles = [typeName].concat(filterTitles);
           // console.log(filterBoxes, filterTitles, 'has');

        } else if (f.indexOf('type') == -1) {
            //add type
            let typeFilter = [['type', 'nodesRemoved']];
            let typeName = '';
            filterTitles = [typeName].concat(filterTitles);
            filterBoxes = typeFilter.concat(filterBoxes);
           // console.log(filterBoxes, filterTitles, 'doesnt have');
    }

    let l = {};

    lenseActions.forEach((lense) => {
        l[lense[0]] = lense[1];
    });

    let lKeys = Object.keys(l);

    let filters = {};
    if (filterBoxes.length >= 1) {
        filterBoxes.map(function (x, i) {
            let title = 'filterBoxTitles' in data.config ? data.config.filterBoxTitles[i] != undefined ? filterTitles[i] : '' : '';
            let lenseType = lKeys.indexOf(x[0]) >= 1 ? l[x[0]] : 'nodesAdd';
            filters[x[0]] = { filterType: x[1], lenseType: lenseType, title: title, order: i + 1 };
        });

    }
    //adds any extras -- shouldnt be needed 
    if (lenseActions.length >= 1) {
        lKeys.forEach((lense) => {
            let order = Object.keys(filters).length + 1;
            if (!(lense in filters)) {
                filters[lense] = { filterType: 'nodesRemoved', lenseType: l[lense], title: '', order: order };
                order += 1;
            }
        })
    }


    questionSet.set({
        labelOrganisation: data.labelOrganisation || '',
        labelProject: data.labelProject || '',
        questions,
        sections,
        config: {
            projectFlag: data.config.projectFlag || 'relm',
            publicProject: data.config.publicProject || false,
            updatable: data.config.updatable || true,
            published: data.config.published || true,
            multiAnswers: data.config.multiAnswers || false,
            multiRings: data.config.multiRings || true,
            timeline: data.config.timeline || false,
            tour: data.config.tour || false,
            delRel: data.config.delRel || [],
            filterActions: filterActionObject,
            translations: data.config.translations || {},
            extraMarkup: data.config.extraMarkup || '',
            filters: filters == undefined ? {} : filters,
            linkActions: linkActionObject,
            colourChanges: colourChanges

        },
        selfsetNum: setSelf,
        survey,
        mapBasic: data.map != undefined ? data.map : {}
    });

    let jsonQS = JSON.stringify(get(questionSet));
    let editQS = JSON.parse(jsonQS);
    editQuestionSet.set(editQS);
    

    console.log(get(questionSet), get(editQuestionSet));

}
 
export const changeNodeKind = (node, index) => {
    const OriginalData = node.data;
    const currentData = get(editQuestionSet);

        //let q = currentData.questions;
        let data: TextNode | Checkbox | Map | Select = {};
        data['dataset'] = OriginalData.dataset;
        data['id'] = OriginalData.id;
    
        if (node.kind == 'Text') {
            data['fieldLabels'] = ('fieldLabels' in OriginalData) ? OriginalData.fieldLabels : [''];
            data['suggestions'] = ('suggestions' in OriginalData) ? OriginalData.suggestions : [''];
            data['manyToMany'] = ('manyToMany' in OriginalData) ? OriginalData.manyToMany : false;
            data['startingFields'] = ('startingFields' in OriginalData) ? OriginalData.startingFields : 10;
            data['checkLocationName'] = false;
            data['extendable'] = false;
            data['maxAnswers'] = ('maxAnswers' in OriginalData) ? OriginalData.maxAnswers : 20;

        }else if (node.kind == 'CheckBox') {

        data['fieldLabels'] = ('fieldLabels' in OriginalData) ? OriginalData.fieldLabels : [''];
        data['choices'] = [''];
        data['minSelected'] = 0;
        data['maxSelected'] = 99;
        data['manyToMany'] = ('manyToMany' in OriginalData) ? OriginalData.manyToMany : false;
        data['other'] = ('other' in OriginalData) ? OriginalData.other : 0;

    } else if (node.kind == 'Select') {

        data['other'] = ('other' in OriginalData) ? OriginalData.other : 0;
        data['manyToMany'] = ('manyToMany' in OriginalData) ? OriginalData.manyToMany : false;
        data['startingFields'] = ('startingFields' in OriginalData) ? OriginalData.startingFields : 10;
        data['fieldLabels'] = ('fieldLabels' in OriginalData) ? OriginalData.fieldLabels : [''];
        data['suggestions'] = ('suggestions' in OriginalData) ? OriginalData.suggestions : [''];



    } else if (node.kind == 'Map') {
        data['helpText'] = '';
        data['currentLocation'] = false;
        data['manyToMany'] =('manyToMany' in OriginalData) ? OriginalData.manyToMany : false;
        data['fieldLabels'] = ('fieldLabels' in OriginalData) ? OriginalData.fieldLabels : [''];
    } else if (node.kind == 'Quant') {
        data['fieldLabels'] = ('fieldLabels' in OriginalData) ? OriginalData.fieldLabels : [''];
        data['minValue'] =  0;
        data['maxValue'] = 99;
        data['questionLabels'] = [''];
        data['percentage'] = false;
    } else if (node.kind == 'DateTime'){
        //need to add
        data['fieldLabels'] = ('fieldLabels' in OriginalData) ? OriginalData.fieldLabels : [''];
    }

        currentData.questions[index].data = data;
        editQuestionSet.set(currentData);
    
}




/*if you change the property to self or to node the dataset needs to change*/
export const changeProps = (property, index, type, pos) => {
    const OriginalData = property.data;
    const currentData = get(editQuestionSet);

        let data = OriginalData;
        if (type == 'self') {
            data.id = "selfProps";
            data.dataset = {
                properties: OriginalData.dataset.properties
               /* name: OriginalData.dataset.name,
                value: OriginalData.dataset.value*/
            }
        } else if (type == 'props') {
            data.id = "nodeProps";
            data.dataset = {
                index: pos,
                properties: OriginalData.dataset.properties
               /* name: OriginalData.dataset.name,
                value: OriginalData.dataset.value*/
            }
        }

        currentData.questions[index].data = data;
        editQuestionSet.set(currentData);
    
}

export const addQuestion = (type:string) => {
    //creates a basic text node or property
    console.log('add', type);
    const kind = 'Text';
    const currentSet = get(editQuestionSet);

    const index = currentSet.questions.length;
    let allIds = currentSet.questions.map((q) => { return q.id });//.sort((a, b) => a.localeCompare(b));
    let found = false;
    let i = index;
    let id = ''
    while (found == false) {
        id = 'question--' + i;
        if (allIds.includes(id)) {
            i += 1;
        } else {
            found = true;
        }
    }

    //console.log(id);

    let newQuestion: Question = {
        id,
        kind: 'Text',
        label: '',
        create: type,
        allowEmpty: false,
        encrypt: {
            project: false,
            consent: false
        },

        //need to run a get datatype
        data : dataStructure(kind, type),

    }
    currentSet.questions.push(newQuestion);

    editQuestionSet.set(currentSet);

}

//needs a value passed over so we know for which one 
export const addSection = () => {
    const currentSet = get(editQuestionSet);
    console.log(currentSet);
    let allIds = Object.keys(currentSet.sections).filter((key) => key !== 'intro' && key !== 'outro');
    let found = false;
    let i = 0;
    let id = ''
    while (found == false) {
        id = 'question--' + i;
        if (allIds.includes(id)) {
            i += 1;
        } else {
            found = true;
        }
    }
    console.log('new id', id);
    currentSet.sections[id] = {title: '', text: ''};

    editQuestionSet.set(currentSet);
    console.log(get(editQuestionSet));


}

export const deleteQuestion = (id) => {
    const currentSet = get(editQuestionSet);
   // let index = currentSet.questions.findIndex((q) => q.id == id);
    currentSet.questions = currentSet.questions.filter((q) => q.id != id);
      //  currentSet.questions.splice(index, 1);
        console.log(currentSet);
        //need to update setself as index changes 
        let setSelf = currentSet.questions.findIndex(q => q.data.id == 'setSelf');
        currentSet.selfsetNum = setSelf;
    editQuestionSet.set(currentSet);
    

    
}

function getNumberFromString(str) {
    const match = str.match(/\d+$/);
    return match ? parseInt(match[0], 10) : null;
  }

export const deleteSection = (id) => {
    const currentSet = get(editQuestionSet);
    let sectionNumber = getNumberFromString(id);
    let sectionId = 'question--'+sectionNumber;
    const { [sectionId]: _, ...newSections } = currentSet.sections; 
    let setSelf = currentSet.questions.findIndex(q => q.data.id == 'setSelf');

    // Update the store correctly
    editQuestionSet.update((set) => ({
        ...set,
        selfsetNum: setSelf,
        sections: newSections
    }));


}

/*export const setOriginalIds = () => {
    const currentSet = get(editQuestionSet);
    let questionIds = currentSet.questions.map((q) => q.id);
    let sectionIds = Object.keys(currentSet.sections).filter((key) => key !== 'intro' && key !== 'outro');
    sectionIds.forEach(section => {
        let questionsPos = questionIds.findIndex((q) => q == section);
        //which would mean found
        if(questionsPos >= 0){
          questionIds.splice(questionsPos, 0, 'section--'+getNumberFromString(section));
        }
      })
      originalIds.set(questionIds);
}*/

//this function returns if checkbox should be checked or not 
export const checkSelf = (index, self) => {
    const currentSet = get(editQuestionSet);

    let setself = {
        self: self,
        message: '',
    }


        //just been checked
        if (self) {
            if (currentSet.selfsetNum == -1) {
                setself.message = 'Do you want to make this node your self node?'
                setself.self = false;
            } else if (currentSet.selfsetNum >= 0) {
                setself.message = 'Do you want to change the self node to this one?'
                setself.self = false;
            }
        } else if (!self) {
            if (currentSet.selfsetNum == index) {
                setself.message = 'Are you sure you want to change this node to not be the self node anymore?'
                setself.self = true;
            }
        }

    
    return setself;



}

export const changeSelf = (index) => {
    const currentSet = get(editQuestionSet);

        let oldindex = currentSet.selfsetNum;
        //if there was another self before 
        if (oldindex >= 0) {
            let oldself = currentSet.questions[oldindex];

            oldself.data.id = 'node';
            oldself.data.dataset = {
                nodeType: currentSet.questions[oldindex].data.dataset.nodeType,
                linkType: '',
                source: '',
                target: '',
                otherKey: [],
                otherNodeType: []
            }
            currentSet.questions[oldindex] = oldself;
        }

        if (index == currentSet.selfsetNum) {
            currentSet.selfsetNum = -1;

        } else {
            let newself = currentSet.questions[index];
            newself.data.id = 'setSelf';
            newself.data.dataset = {
                nodeType: currentSet.questions[index].data.dataset.nodeType,
                self: true,
                survey: currentSet.survey,
                otherNodeType: []

            }

            currentSet.questions[index] = newself;
            currentSet.selfsetNum = index;
        }

        editQuestionSet.set(currentSet);
    
}

//takes the list of ids and then orders 
export const reorderQuestions = (newQuestionOrder) => {
    const currentSet = get(editQuestionSet);

    /*currentSet.questions.sort(function (a, b) {
        return order.indexOf(a.id) - order.indexOf(b.id);
    })*/

    currentSet.questions = currentSet.questions = newQuestionOrder.map(id => 
        currentSet.questions.find(q => q.id === id)
    );

    //have to refind selfset incase its moved 
    let setSelf = currentSet.questions.findIndex(q => q.data.id == 'setSelf');
    currentSet.selfsetNum = setSelf;

    editQuestionSet.set(currentSet);
}

export const addField = (index, key) => {
    const currentSet = get(editQuestionSet);

        currentSet.questions[index].data[key].push("");
        editQuestionSet.set(currentSet);
    
}

export const addEmptyObject = (index, type) => {

    const currentSet = get(editQuestionSet);

        currentSet.questions[index].data.dataset[type].push({});
        editQuestionSet.set(currentSet);
    
}

export const addLinkEmptyObject = (index, linkPos, type) => {
    const currentSet = get(editQuestionSet);
    currentSet.questions[index].data.dataset.links[linkPos][type].push({}); 
    editQuestionSet.set(currentSet);
}

export const removeLinkEmptyObject = (index, linkPos, i, type) => {
    const currentSet = get(editQuestionSet);
  
    currentSet.questions[index].data.dataset.links[linkPos][type].splice(i, 1);
    editQuestionSet.set(currentSet);
}

export const removeField = (index, key, i) => {
    const currentSet = get(editQuestionSet);
   
        currentSet.questions[index].data[key].splice(i, 1)
        editQuestionSet.set(currentSet);
    
}

export const removeKeyValue = (index, i, type) => {
    const currentSet = get(editQuestionSet);
  
        currentSet.questions[index].data.dataset[type].splice(i, 1);
        editQuestionSet.set(currentSet);
    
}

//something weird is happing with the data where translation data is save before the data is saved 
const getTranslations = (oldTranslations, newTranslations, newKeys) => {
    console.log(oldTranslations, newTranslations, newKeys);
    for (let key in newTranslations) {
        //check if the value is empty
        if (newTranslations[key].length == 0) {
            delete newTranslations[key];
            continue;
        }
        //checks if its a new value or if the value was in the old data, if not gets rid of it
        if (!(key in oldTranslations) && newKeys.indexOf(key) == -1) {
            delete newTranslations[key];
            continue;
        }
    }
    return newTranslations;
}

const getFilters = (filters, keys, originalFilterActions) => {
    console.log(filters, keys);
    let filterBoxes = [];
    let filterBoxTitles = [];
    let lenseActions = [];
    let filterActions = Object.entries(originalFilterActions);


    for (let key in filters) {
        if (key != 'type') {
            if (keys.includes(key) == false) {
                delete filters[key];
            }
        }
    }


    let sortedFilters = Object.entries(filters)
        .sort(([, a], [, b]) => a.order - b.order);

    sortedFilters.forEach((filter) => {
        console.log(filter[1].title, filter[0], filter[1].filterType);
        filterBoxTitles.push(filter[1].title);
        filterBoxes.push([filter[0], filter[1].filterType]);
        lenseActions.push([filter[0], filter[1].lenseType]);
        //add any new filters to mass list of actions
        if (originalFilterActions[filter[0]] != undefined) {
            filterActions.push([filter[0], filter[1].filterType]);
        }

    });


    return [filterBoxes, filterBoxTitles, lenseActions, filterActions];
};

//this needs looking at 
export const changeMapProject = (map) => {
    const currentSet = get(editQuestionSet);
    if (map) {
        currentSet['mapBasic'] = {
            lat: 0,
            long: 0,
            zoom: 0
        }
    } else {
        currentSet['mapBasic'] = {};
        //will need to remove all maps 
        currentSet.questions.forEach((q, i) => {
            if (q.kind == 'Map') {
                currentSet.questions.splice(i, 1);
            }
        })

    }
    editQuestionSet.set(currentSet);

}

export const getLinkActions = (actions, keys) => {
    let linkActions = [];


    //check that the key still exsits 
    //havent yet added properly keys so would always remove 
    /*for (let key in actions) {

            if (keys.includes(key) == false) {
                delete actions[key];
            }
       
    }*/


    for (let [key, value] of Object.entries(actions)) {
        linkActions.push([value.type, key, value.colour]);
    }


    return linkActions;
}
 

export const returnDataset = () => {
    //note when return translations need to copare original and new and only add the ones that have types in the set 
    //do i need a check that there is a self node ??
    const currentSet = get(editQuestionSet);
    const originalSet = get(questionSet);

    console.log(currentSet, originalSet);

    let newKeys = [];
    currentSet.questions.forEach((q) => {
        if (q.create == 'node') {
            newKeys.push(q.data.dataset.nodeType);
        }

        if (q.create == 'properties') {
            newKeys.push(q.data.dataset.name);
        }
    })
   // const translations = getTranslations(originalSet.config.translations, currentSet.config.translations, newKeys);

   /*let filterKeys = [];
    currentSet.questions.forEach((q) => {
        if (q.create == 'node') {
            //filterKeys.push(Object.keys(q.data.dataset.otherKey));
            if (q.data.dataset.otherNodeType != undefined) {
                Object.values(q.data.dataset.otherNodeType).map((t) => t.key).forEach((t) => {
                    filterKeys.push(t);
                });;
            }

        }

        if (q.create == 'properties') {
            filterKeys.push(q.data.dataset.name);
        }
    })*/

   // const [filterBoxes, filterBoxTitles, lenseActions, filterActions] = getFilters(currentSet.config.filters, filterKeys, currentSet.config.filterActions);


    /*let linkActionKeys = [];
    currentSet.questions.forEach((q) => {
        if (q.create == 'node') {
            q.data.dataset.links.forEach((link) => {
            if (link.otherKey != undefined) {
                Object.values(link.otherKey).map((t) => t.key).forEach((t) => {
                    linkActionKeys.push(t);
                });
            }
            })
        }
        //this needs work
        //need to find all the suggestion and choices of the chosen ones 
      /*  if (q.create == 'properties') {
            if (q.data.dataset.value == '__answers__') {
                //get names 
                if (q.data.dataset.index == '__self__') {

                } else {
                    let answers = currentSet.questions[q.data.dataset.index].
                }

            } else {
                //dont think there is a else case but will see 
            }

        }
    })*/


   // const linkActions = getLinkActions(currentSet.config.linkActions, linkActionKeys);

   

    let newQuestionSet = {
        labelOrganisation: currentSet.labelOrganisation,
        labelProject: currentSet.labelProject,
        sections: currentSet.sections,
        questions: jsonQuestions(currentSet.questions),
        config: {
            projectFlag: currentSet.config.projectFlag,
        publicProject: currentSet.config.publicProject,
        updatable: currentSet.config.updatable,
        published: currentSet.config.published,
        multiAnswers: currentSet.config.multiAnswers,
        multiRings: currentSet.config.multiRings,
        timeline: currentSet.config.timeline,
        tour: currentSet.config.tour,
        delRel: getDelRel(currentSet.questions),
            //filterActions: filterActions,
            //translations: translations,
            extraMarkup: currentSet.config.extraMarkup,
            //filterBoxes,
            //filterBoxTitles,
           // lenseActions,
           // linkActions,
            colourChanges: currentSet.config.colourChanges
        }
    };

    if (Object.keys(currentSet.mapBasic).length > 0) {
        newQuestionSet['map'] = currentSet.mapBasic;
    }

    return newQuestionSet;
}

function extractNumber(value) {
    const match = value.match(/\d+/); // Looks for one or more digits
    return match ? parseInt(match[0], 10) : null; // Converts to number if found, else returns null
}


const getDelRel = (questions) => {
    let delRel = [];
    //creates the basic shape
    questions.forEach((q, index) => {
        delRel.push([index, null]);
    });


    questions.forEach((q, index) => {
        console.log('question--', index);
        if(q.create == 'node'){
            if (q.data.id != 'setSelf') {
                q.data.dataset.links.forEach((link) => {
                    const sourceNumber = extractNumber(link.source);
                    const targetNumber = extractNumber(link.target);
                    if(sourceNumber != null){
                        if(delRel[sourceNumber][1] != null){
                            console.log("not null")
                            if(typeof delRel[sourceNumber][1]  == "number"){
                                delRel[sourceNumber][1] = [delRel[sourceNumber][1], index];
                            }else {
                                delRel[sourceNumber][1].push(index);
                            }
                        }else{
                            delRel[sourceNumber][1] = index;
                        }
                    }
                    if(targetNumber != null){
                        if(delRel[targetNumber][1] != null){
                            if(typeof delRel[targetNumber][1] == "number"){
                                delRel[targetNumber][1] = [delRel[targetNumber][1], index];
                            }else {
                                delRel[targetNumber][1].push(index);
                            }
                        }else{
                            delRel[targetNumber][1] = index;
                        }
                    }
                });
            }   
        } else if (q.create == 'properties') {
            if (q.data.id == 'nodeProps') {
                let connectedQuestion = getNumberFromString(q.data.dataset.index);
                if(delRel[connectedQuestion][1] != null){
                    if(typeof delRel[connectedQuestion][1]  == "number"){
                        delRel[connectedQuestion][1] = [delRel[connectedQuestion][1], index];
                    }else {
                        delRel[connectedQuestion][1].push(index);
                    }
                }else{
                    delRel[connectedQuestion][1] = index;
                }
        
            }
        }
    });

    return delRel;

}



//sure there is a way to do this once and then equal to each other 
export const questionSet = writable<QuestionSet>({
    labelOrganisation: '',
    labelProject: '',
    questions: null,
    sections: null,
    config: {
        filterActions: {},
        translations: {},
        extraMarkup: '',
        filters: {},
        linkActions: {},
        colourChanges: {},
        projectFlag: 'relm',
        publicProject: false,
        updatable: true,
        published: true,
        multiAnswers: false,
        multiRings: true,
        timeline: false,
        tour: false,
        delRel: []
    },
    selfsetNum: -1,
    survey: '',
    mapBasic: {}

});

export const editQuestionSet = writable<QuestionSet>({
    labelOrganisation: '',
    labelProject: '',
    questions: null,
    sections: null,
    config: {
        filterActions: {},
        translations: {},
        extraMarkup: '',
        filters: {},
        linkActions: {},
        colourChanges: {},
        projectFlag: 'relm',
        publicProject: false,
        updatable: true,
        published: true,
        multiAnswers: false,
        multiRings: true,
        timeline: false,
        tour: false,
        delRel: []
    },
    selfsetNum: -1,
    survey: '',
    mapBasic: {}

});


//export const originalIds = writable([]);