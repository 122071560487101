<script lang="ts">
  import Node from "./Node.svelte";
  import Properties from "./AddProperties.svelte";
  import Section from "./Section.svelte";
  import {reorderQuestions, editQuestionSet} from "../../stores/questionset";

  // import {flip} from "svelte/animate";
   import {dndzone} from "svelte-dnd-action";

   //colour is broken i think 
   // link many to many needs work 
   // reordering in section form something is wrong 

   export let reorder;
   export let questionSet;
   export let map;

   function getNumberFromString(str) {
    const match = str.match(/\d+$/);
    return match ? parseInt(match[0], 10) : null;
  }
   


   const combineSectionsAndQuestions = () => {

    let orderedRefs = [];
    let sections = Object.fromEntries(
      Object.entries(questionSet.sections).filter(([key]) => key !== 'intro' && key !== 'outro')
    );

    questionSet.questions.forEach((question, qIndex) => {
      let sectionEntry = Object.entries(sections).find(([sectionId]) => sectionId == question.id);
      if(sectionEntry) {
        orderedRefs.push({
          type: "section",
          id: 'section--'+getNumberFromString(sectionEntry[0]),
          sIndex: getNumberFromString(sectionEntry[0]),
          orderIndex: orderedRefs.length
        })
      }

      orderedRefs.push({
        type: "question",
        id: question.id,
        qIndex: qIndex,
        orderIndex: orderedRefs.length
      })
    })

    return orderedRefs;

   /* if(Object.keys(sections).length > 0){
      //there is questions sections
      Object.keys(sections).forEach(section => {
        let questionsPos = combinedItems.findIndex((q) => q.id == section);
        //which would mean found
        if(questionsPos >= 0){
          combinedItems.splice(questionsPos, 0, {id: 'section--'+getNumberFromString(section), create: 'section', ...sections[section]});
        }
      })

    }
    console.log(combinedItems);

    return combinedItems;*/
   }
   let items;

   $: console.log('qs', questionSet);
   $: questionSet, items = combineSectionsAndQuestions();


    const flipDurationMs = 300;
    function handleDndConsider(e) {
        items = e.detail.items;
    }
    function handleDndFinalize(e) {
    let newItems = e.detail.items;
    console.log(newItems);

    // Prevent sections from being placed after other sections
    for (let i = 1; i < newItems.length; i++) {
        if (newItems[i].type === "section" && newItems[i - 1].type === "section") {
            console.warn("Invalid move: Sections cannot be placed after other sections.");
            return; // Cancel the reordering
        }
    }

    // Step 1: Separate out the questions in the newItems array.
    const newQuestionsOrder = newItems.filter(item => item.type === "question");
    console.log(newQuestionsOrder);

    // Step 2: Update the order of questions based on their new positions
    /*newQuestionsOrder.forEach((item, index) => {
        // Find the question in the original questionSet
        const question = questionSet.questions.find(q => q.id === item.id);
        if (question) {
            // Update the id based on the new index position in the newItems
            question.id = `question--${index}`;
            question.qIndex = index;  // Update the qIndex based on the new position
        }
    });*/

    console.log(questionSet.questions)

    // Step 3: Reorder the `questionSet.questions` based on the new `newQuestionsOrder`
    questionSet.questions = newQuestionsOrder.map(item =>
        questionSet.questions.find(q => q.id === item.id)
    );

    questionSet.questions.forEach((item, index) => {
      item.id = `question--${index}`;
    })

    console.log(questionSet.questions);

    newItems.forEach((item, index) => {
          if (item.type == 'section') {
        // Make sure the next item exists and is a question
        let newIndex = index + 1 < newItems.length && newItems[index + 1].type === 'question'
          ? newItems[index + 1].qIndex 
          : item.sIndex;  // Default to the original section index if no question follows

        // Only move the section if the new index is different
        if (item.sIndex !== newIndex) {
          // Find the original section and copy it
          let copySection = questionSet.sections['question--' + item.sIndex];

          // Check if the section exists in the original index
          if (copySection) {
            // Remove the section from the old index
            delete questionSet.sections['question--' + item.sIndex];

            // Add the section to the new index
            questionSet.sections['question--' + newIndex] = copySection;
          } else {
            console.warn(`Section not found at index: ${item.sIndex}`);
          }
        }
      }
    });


    // Step 5: Recalculate and update `selfsetNum` if needed
    let setSelfIndex = questionSet.questions.findIndex(q => q.id === 'setSelf');
    if (setSelfIndex !== -1) {
        questionSet.selfsetNum = setSelfIndex;
    }

    // Step 6: Update the sections in questionSet based on updated section positions
   // questionSet.sections = updatedSections;

    // Step 7: Update the store with the reordered set
    $editQuestionSet = questionSet;

    // Step 8: Update `items` to reflect the new structure of sections and questions
    items = combineSectionsAndQuestions();
}



     function componentConstructorClass(componentName){   
      switch(componentName){
        case 'node': return Node;
        case 'properties': return Properties;
        case 'section': return Section;

    }
   }

</script>

<svelte:head>
  <meta
    name="viewport"
    content="width=device-width, initial-scale=1.0, user-scalable=no" />
</svelte:head>

<div style="list-style-type:none;" id="reorder-container">
  {#if reorder}

    <section class="order-section" use:dndzone="{{items, flipDurationMs}}" on:consider="{handleDndConsider}" on:finalize="{handleDndFinalize}">
        {#each items as ref (ref.id)}
        <div class="{ref.type === 'section' ? ref.type : questionSet.questions[ref.qIndex].create}" ><!--- animate:flip="{{duration: flipDurationMs}}">-->
            <div class="intro">	
              {#if ref.type == 'section'}
              <!--need to add section original id-->
              <div class="sticker">
                S{ref.sIndex + 1}
              </div>
              <h2>{questionSet.sections['question--'+ref.sIndex].title}</h2>
              {:else} 
                <div class="sticker">
                 Q{ref.qIndex + 1}
                </div>
                <h2>{questionSet.questions[ref.qIndex].label}</h2>
              {/if}
            </div>
        </div>
        {/each}
    </section>

  {:else}

    {#each items as ref (ref.id)}
    
      <div
        class="item">     
       <svelte:component  this={componentConstructorClass(ref.type === "section" ? ref.type : questionSet.questions[ref.qIndex].create)} 
       data={ref.type === "section" ? questionSet.sections['question--'+ref.sIndex] : questionSet.questions[ref.qIndex]}
      self={ref.type === "question" && ref.qIndex === questionSet.selfsetNum}
       map={map} 
       index={ref.type === "section" ? ref.sIndex : ref.qIndex}
       />
      </div>

       {/each}
  {/if}

</div>


