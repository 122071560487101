<script lang="ts">
  import { onMount } from "svelte";
  import { fade } from "svelte/transition";
  import {
    editQuestionSet,
    addField,
    removeField,
    addEmptyObject,
    addLinkEmptyObject,
    removeLinkEmptyObject,
    removeKeyValue,
  } from "../../stores/questionset";

  export let data;
  export let index;

  //need to add checkLocationName check 

  //many to many in scope 

  let colour = false;
  let colourButton = "Add custom colours";

  let isMapText = "checkLocationName" in data.data ? data.data.checkLocationName : false;
  $: !isMapText, data.data.checkLocation = false;

  //let reseveredWord = ['__datasetNodeMatches__']

  let suggestions =
    "suggestions" in data.data
      ? data.data.suggestions.every((el) => el == "")
        ? "empty"
        : data.data.suggestions == "__datasetNodeMatches__"
        ? data.data.suggestions[0]
        : "other"
      : "";

  //let suggestions = (typeof(data.data.suggestions) == 'string') ? data.data.suggestions : data.data.suggestions.every((el) => el == '') ? 'empty' : 'other';
  //let suggestionsArray = suggestions == 'other' ? data.data.suggestions : [''];
  let suggestionsArray = data.data.suggestions || [""];

  $: data.data.suggestions =
    suggestions == "other"
      ? suggestionsArray
      : suggestions != "empty"
      ? [suggestions]
      : [""];

  let numOfAnswers =
    "fieldLabels" in data.data && data.data.fieldLabels.length != undefined
      ? data.data.fieldLabels.length
      : 1;
  $: emptyLables = emptyLabels(numOfAnswers);

  let fieldLabels =
    "fieldLabels" in data.data
      ? typeof data.data.fieldLabels == "string"
        ? data.data.fieldLabels
        : data.data.fieldLabels.every((el) => el == "")
        ? "empty"
        : "other"
      : "other";
  let labelsArray =
    "fieldLabels" in data.data
      ? typeof data.data.fieldLabels == "object"
        ? data.data.fieldLabels
        : [""]
      : [""];

  $: data.data.fieldLabels =
    fieldLabels == "other"
      ? labelsArray
      : fieldLabels != "empty"
      ? fieldLabels
      : emptyLables;

  function emptyLabels(num) {
    let empty = [];
    for (let i = 0; i < num; i++) {
      empty.push("");
    }
    return empty;
  }

  function onChangeFieldLabel(el) {
      if(fieldLabels == 'other'){
          el.currentTarget.parentNode.parentNode.querySelector('.fieldLabels').firstChild.focus();      
      }
  }

  function onChangeSuggest(el){
      if(suggestions == 'other'){
          el.currentTarget.parentNode.parentNode.querySelector('.sug').firstChild.focus();
      }
   
  }

    function addLinkAction(key){
      if(!(key in $editQuestionSet.config.linkActions)){
          $editQuestionSet.config.linkActions[key] = {type: 'collaborationType', colour: 'highlight'};
      }


  }

  function removeLinkAction(key){
      if(key in $editQuestionSet.config.linkActions){
          delete $editQuestionSet.config.linkActions[key];
          $editQuestionSet.config.linkActions = $editQuestionSet.config.linkActions;
      }
  }

  function addLink(){
    data.data.dataset.links = [...data.data.dataset.links, {
                    linkType: [],  
                    source: '',
                    target: '',
                    otherKey: [], 
                }];
  }

  function removeLink(index){
    data.data.dataset.links.splice(index, 1);
  }


  /*function getLabels(fieldLabels, num){
	  let newFieldLabels = [];
	  if(fieldLabels.length != num){
		  newFieldLabels = fieldLabels;
		  while(newFieldLabels.length != num){
			  if(newFieldLabels.length < num){
				  newFieldLabels.push('');
			  }
			  if(newFieldLabels.length > num){
				  newFieldLabels.pop();
			  }
		  }
	  }else{
		  newFieldLabels = fieldLabels;
	  }
		return newFieldLabels;
  }*/

  //if there is no setself value u cant have the links set to setself otherwise it wont work
  /* $: if(data.data.dataset.source == '__self__' && $editQuestionSet.selfsetNum == -1){
	  data.data.dataset.source = '';
  }

  $: if(data.data.dataset.target == '__self__' && $editQuestionSet.selfsetNum == -1){
	  data.data.dataset.target = '';
  }*/
</script>

{#if data}
<div class="q-bubble">
    {#if 'fieldLabels' in data.data}
        <div class="q-container">
            <label for="labels-dropdown">Add a label to the answer field?</label>
            <select
              name="labels-dropdown"
              id="labels-dropdown"
                    bind:value="{fieldLabels}"
              on:change="{onChangeFieldLabel}"
            >
            {#if index-1 >= 0}
                <option value="__priorAnswers__">Prior Answers</option>
            {/if} 
            {#each $editQuestionSet.questions as question, i} 
                {#if i != index && i != $editQuestionSet.selfsetNum } 
                    {#if $editQuestionSet.questions[i].kind == 'Text'}
                        <option value="{question.id}">Question {i+1} Answers</option>
            {/if} {/if} {/each}
            <option value="other">Set custom labels</option>
            <option value="empty">No</option>
            </select>
        </div>
        {#if fieldLabels == 'other'} 
            {#each labelsArray as labels, i}
                <div class="q-container-long fieldLabels">
                <!--<label for="option-{i}">Answer label {i+1}</label>--->
                <input
                  type="text"
                  id="option-{i}"
                  name="option-{i}"
                  bind:value="{labels}"

                />
                {#if labelsArray.length > 1}
                    <button class="btn btn-node" on:click|preventDefault="{() => {removeField(index, 'fieldLabels', i); labelsArray = data.data.fieldLabels;}}">
                      Delete field
                    </button>
                {/if}
                </div>
            {/each}
            <div>
                <button class="btn btn-node" on:click|preventDefault="{() => {addField(index, 'fieldLabels'); labelsArray = data.data.fieldLabels;}}">
                    Add field
                </button>
            </div>
        {/if} 
        {#if fieldLabels == 'empty'}
            <div class="q-container num">
                <label for="num-empty">How many answers can they give</label>
                <input
                  type="number"
                  min="1"
                  step="1"
                  id="num-empty"
                  name="num-empty"
                  bind:value="{numOfAnswers}"
                />
            </div>
        {/if}
    {/if}
    {#if 'manyToMany' in data.data}
        <div class="q-container">
          <label for="many-to-many">Is this a many to many question?</label>
          <div class="checkbox-container">
            <input
                type="checkbox"
                id="many-to-many"
                name="many-to-many"
                bind:checked="{data.data.manyToMany}"
            />
        </div>
        </div>
        {#if data.data.manyToMany === true}
        <div class="q-container num">
          <label for="starting-num">How many answers for each many to many?</label>
          <input
            type="number"
            min="1"
            step="1"
            id="starting-num"
            name="starting-num"
            bind:value="{data.data.startingFields}"
          />
      </div>
        {/if}
    {/if}
    {#if 'extendable' in data.data}
      <div class="q-container">
        <label for="extendable">Is this question extendable?</label>
        <div class="checkbox-container">
          <input
              type="checkbox"
              id="extendable"
              name="extendable"
              bind:checked="{data.data.extendable}"
          />
      </div>
      </div>
      {#if data.data.extendable === true}
      <div class="q-container num">
        <label for="max-num">What is the max amount of answers?</label>
        <input
          type="number"
          min="1"
          step="1"
          id="max-num"
          name="max-num"
          bind:value="{data.data.maxAnswers}"
        />
    </div>
      {/if}
    {/if}
    <div class="q-container">
      <label for="map-question">Is this question naming a map location?</label>
      <div class="checkbox-container">
      <input
          type="checkbox"
          id="map-question"
          name="map-question"
          value="empty"
          bind:checked="{isMapText}"
      />
      </div>
  </div>
  {#if isMapText}
    <div class="q-container">
      <label for="check-location">Do you want to check if map name has a location?</label>
      <div class="checkbox-container">
      <input
          type="checkbox"
          id="check-location"
          name="check-location"
          value="empty"
          bind:checked="{data.data.checkLocationName}"
      />
      </div>
  </div>
  {/if}
    {#if 'allowEmpty' in data}
    <div class="q-container">
        <label for="allow-empty">Require an answer?</label>
        <div class="checkbox-container">
        <input
            type="checkbox"
            id="allow-empty"
            name="allow-empty"
            value="empty"
            bind:checked="{data.allowEmpty}"
        />
        </div>
    </div>
    {/if}
    {#if 'suggestions' in data.data}
        <div class="q-container">
            <label for="suggestion-dropdown">Automatically suggest answers?</label>
            <select
              name="suggestion-dropdown"
              id="suggestion-dropdown"
              bind:value="{suggestions}"
              on:change="{onChangeSuggest}"
            >
              <option value="empty">No</option>
              <option value="__datasetNodeMatches__">Matching type answers</option>
              <option value="other">Set Options</option>
            </select>
        </div>
        {#if suggestions == 'other'} 
            {#each suggestionsArray as suggestion, i}
                <div class="q-container-long sug">
                    <!--<label for="option-{i}">Option (label)</label>--->
                    <input
                      type="text"
                      id="option-{i}"
                      name="option-{i}"
                      bind:value="{suggestion}"
                    />
                    {#if suggestionsArray.length > 1}
                    <button class="btn btn-node" on:click|preventDefault="{() => {removeField(index, 'suggestions', i); suggestionsArray = data.data.suggestions;}}">
                        Delete field
                    </button>
                    {/if}
                </div>
            {/each}
            <button class="btn btn-node" on:click|preventDefault="{() => {addField(index, 'suggestions'); suggestionsArray = data.data.suggestions;}}">
                Add field
            </button>
        {/if} 
    {/if}
</div>
{#if 'create' in data}
    {#if data.create != 'properties'} 
        {#if data.data.id != 'setSelf' } 
            {#if data.data.dataset}
            <div class="q-bubble">
            {#each data.data.dataset.links as link, linkIndex}
            
            
                <div class="q-container">
                    <label for="source">What is the link source</label>
                    <select name="source" id="source" bind:value="{link.source}">
                        {#if $editQuestionSet.selfsetNum > -1}
                            <option value="__self__">self</option>
                        {/if}
                        <option value="__answers__">answers</option>
                        {#if index-1 >= 0}
                            {#if $editQuestionSet.questions[index-1].create == 'node'}
                            <option value="__priorAnswers__">Prior Answers</option>
                            <option value="__priorAnswer__">Prior Answer</option>
                            {/if}
                        {/if}

                        {#each $editQuestionSet.questions as question, i}
				                {#if i != index && i != $editQuestionSet.selfsetNum }
                        <option value={`__answer${i}__`}> answer {i}</option>
                        <option value={`__answers${i}__`}> answers {i}</option>
                        <option value={`__manyAnswer${i}__`}> many Answer {i}</option>
                        <option value={`__manyAnswers${i}__`}> many Answer {i}</option>
				                {/if}
			                {/each}
                    </select>
                </div>
                <div class="q-container">
                    <label for="target">What is the link target</label>
                    <select name="target" id="target" bind:value="{link.target}">
                        {#if $editQuestionSet.selfsetNum > -1}
                            <option value="__self__">self</option>
                        {/if}
                        <option value="__answers__">answers</option>
                        {#if index-1 >= 0 }
                        {#if $editQuestionSet.questions[index-1].create == 'node'}
                            <option value="__priorAnswers__">Prior Answers</option>
                            <option value="__priorAnswer__">Prior Answer</option>
                            {/if}
                        {/if}
                        {#each $editQuestionSet.questions as question, i}
				                {#if i != index && i != $editQuestionSet.selfsetNum }
                        <option value={`__answer${i}__`}> answer {i}</option>
                        <option value={`__answers${i}__`}> answers {i}</option>
                        <option value={`__manyAnswer${i}__`}> many Answer {i}</option>
                        <option value={`__manyAnswers${i}__`}> many Answer {i}</option>

				                {/if}
			                {/each}
                    </select>
                </div>
                <div class="q-container">
                    <label for="type">What is the link type</label>
                    <input
                      type="text"
                      id="type"
                      name="type"
                      bind:value="{link.linkType}"
                    />
                </div>
            <div>
                <button class="btn btn-node" on:click|preventDefault="{() => {addLinkEmptyObject(index, linkIndex, 'otherKey')}}">
                    Add custom value
                </button>
            </div>
            {#if (link.otherKey.length ?? 0) >= 1} 
                {#each link.otherKey as pair, i}
                    <div class="q-container-double">
                        <div class="q-container">
                            <label for="key-{i}">Key</label>
                            <input type="text" id="key-{i}" name="key-{i}" bind:value="{pair.key}" />
                        </div>
                        <div class="q-container">
                            <label for="value-{i}">Value</label>
                            <input
                                type="text"
                                id="value-{i}"
                                name="value-{i}"
                                bind:value="{pair.value}"
                            />
                        </div>
                    </div>


                    {#if $editQuestionSet.config.linkActions[pair.key] == undefined && pair.key.length >= 1}
                      <div>
                        <button class="btn btn-node" on:click|preventDefault="{() =>{addLinkAction(pair.key)}}">
                          Add Link Action
                        </button>
                      </div>
                      {:else}
                                            <div class="q-container">
                                                <label for="link-type">What Type of link is this?</label>
                                                <select name="link-type" id="link-type" bind:value="{$editQuestionSet.config.linkActions[pair.key].type}">
                                                        <option value="linkingCapital">Linking</option>
                                                        <option value="bridgingCapital">Bridging</option>
                                                        <option value="collaborationType">Collaboration</option>
   
                                                </select>
                                            </div>

                                            <div class="q-container">
                                                <label for="link-colour">What Colour would you like?</label>
                                                <select name="link-colour" id="link-colour" bind:value="{$editQuestionSet.config.linkActions[pair.key].colour}">
                                                        <option value="highlight">Pink</option>
                                                        <option value="bridges">Blue</option>
                                                        <option value="highlightCommission">Purple</option>
                                                        <option value="highlightProduct">Red</option>
                                                        <option value="hightlightProcess">Light pink</option>
                                                </select>
                                            </div>
                       <div>
                        <button class="btn btn-node" on:click|preventDefault="{() =>{removeLinkAction(pair.key)}}">
                          Remove Link Action
                        </button>
                      </div>
                      {/if}
                    
                    <div>
                        <button class="btn btn-node" on:click|preventDefault="{() => {removeLinkEmptyObject(index, linkIndex, i, 'otherKey'); removeLinkAction(pair.key)}}">
                            Delete Link Pair
                        </button>
                    </div>
                {/each} 
            {/if}
        
        {#if linkIndex > 1}
        <div>
          <button class="btn btn-node" on:click|preventDefault="{() => {removeLink(index)}}">
              Delete Link
          </button>
      </div>
      {/if}
        {/each}
        <div>
          <button class="btn btn-node" on:click|preventDefault="{() => {addLink()}}">
              Add Link
          </button>
      </div>
    </div>
        {/if}
{/if} {/if} {/if}
<!--{#if 'create' in data}
    {#if data.create != 'properties'}
<div class="q-bubble">
        <div>
            <button
              class="btn btn-node"
              on:click|preventDefault="{() => {
                                   colour = !colour;
                                   if(colour){
                                       colourButton = 'Remove custom colours';
                                   }else{
                                       colourButton = 'Add custom colours';
                                   }
                               }
                               }"
            >
              {colourButton}
            </button>
        </div>
      {#if colour}
            <div class="q-container-double">
                <div class="q-container">
                  <label for="col-1">inner</label>
                  <input
                    type="text"
                    id="col-1"
                    name="col-1"
                    bind:value="{data.colours.colour1}"
                  />
                </div>
                <div class="q-container">
                  <label for="col-2">outer</label>
                  <input
                    type="text"
                    id="col-2"
                    name="col-2"
                    bind:value="{data.colours.colour2}"
                  />
                </div>
            </div>
        {/if} 
</div>
{/if}
    {/if}-->


{/if}
